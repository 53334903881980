<template>
  <div class="flex flex-wrap justify-between items-center">
    <h1 class="text-lg font-bold">Escalas</h1>
    <div class="flex flex-wrap gap-2 text-sm font-bold">
      <filters-vue />
    </div>
  </div>
  <div class="flex flex-wrap gap-5">
    <filter-btn :text="dateFilter?.text" :shouldRemove="false" />
    <filter-btn
      v-for="unit of unitsFilter"
      :key="unit.text"
      :text="unit?.text"
      @onRemove="removeUnitsFilter(unit?.id)"
    />
    <filter-btn
      v-for="hour of hoursFilter"
      :key="hour.text"
      :text="hour?.text"
      @onRemove="removeHoursFilter(hour.text)"
    />
    <filter-btn
      v-for="schedule of schedulesFilter"
      :key="schedule.text"
      :text="schedule?.text"
      @onRemove="removeSchedulesFilter(schedule?.id)"
    />
    <filter-btn v-if="valueFilter" :text="valueFilter" @onRemove="removeValueFilter" />
    <filter-btn
      v-if="paymentValueFilter"
      :text="paymentValueFilter"
      @onRemove="removePaymentValueFilter"
    />
    <filter-btn v-if="checkinFilter" :text="checkinFilter" @onRemove="removeCheckinFilter" />
    <filter-btn v-if="checkoutFilter" :text="checkoutFilter" @onRemove="removeCheckoutFilter" />
    <filter-btn v-if="inCashFilter" :text="inCashFilter" @onRemove="removeInCashFilter" />
    <filter-btn
      v-if="advanceRequestedFilter"
      :text="advanceRequestedFilter"
      @onRemove="removeAdvanceRequestedFilter"
    />
    <filter-btn
      v-if="advancePaidFilter"
      :text="advancePaidFilter"
      @onRemove="removeAdvancePaidFilter"
    />
    <filter-btn v-if="statusFilter" :text="statusFilter" @onRemove="removeStatusFilter" />
    <filter-btn
      v-if="stationTypeFilter"
      :text="stationTypeFilter"
      @onRemove="removeStationTypeFilter"
    />
    <filter-btn v-if="ourTeamFilter" :text="ourTeamFilter" @onRemove="removeOurTeamFilter" />
    <filter-btn
      v-if="openedStationFilter"
      :text="openedStationFilter"
      @onRemove="removeOpenedStationFilter"
    />
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import FilterBtn from './components/FilterBtn.vue'
import FiltersVue from './filters/index.vue'
import { useStationStore } from '@/pinia-store/useStationStore'
import { storeToRefs } from 'pinia'
import { addDays, format } from 'date-fns'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { removeFilterDashboardOpt } from './components/useFilterDashboard'
import { parseStringToBool } from '@/utils/string'

export default {
  components: {
    FilterBtn,
    FiltersVue
  },
  setup() {
    const stationsStore = useStationStore()
    const healthUnitStore = useHealthUnitStore()
    const scheduleStore = useScheduleStore()
    const { listStationsQuery } = storeToRefs(stationsStore)
    const { healthUnitsMap } = storeToRefs(healthUnitStore)
    const { schedulesMap } = storeToRefs(scheduleStore)
    const removeUnitsFilter = (unitId: string) => {
      stationsStore.removeUnitFilter(unitId)
    }

    const removeSchedulesFilter = (scheduleId: string) => {
      stationsStore.removeScheduleFilter(scheduleId)
    }

    const removeHoursFilter = (text) => {
      const hoursSplited = text.split('-')
      const startHour = hoursSplited[0]
      const endHour = hoursSplited[1]
      stationsStore.removeHoursFilter(startHour, endHour)
    }

    const removeValueFilter = async () => {
      stationsStore.removeFilter('valueMin')
      stationsStore.removeFilter('valueMax')
      removeFilterDashboardOpt('valueMin')
      removeFilterDashboardOpt('valueMax')
      await stationsStore.getListStations()
    }

    const removePaymentValueFilter = async () => {
      stationsStore.removeFilter('paymentValueMin')
      stationsStore.removeFilter('paymentValueMax')
      removeFilterDashboardOpt('paymentValueMin')
      removeFilterDashboardOpt('paymentValueMax')
      await stationsStore.getListStations()
    }

    const removeCheckinFilter = async () => {
      stationsStore.removeFilter('withCheckin')
      removeFilterDashboardOpt('withCheckin')
      await stationsStore.getListStations()
    }

    const removeCheckoutFilter = async () => {
      stationsStore.removeFilter('withCheckout')
      removeFilterDashboardOpt('withCheckout')
      await stationsStore.getListStations()
    }

    const removeInCashFilter = async () => {
      stationsStore.removeFilter('inCash')
      removeFilterDashboardOpt('inCash')
      await stationsStore.getListStations()
    }

    const removeOpenedStationFilter = async () => {
      stationsStore.removeFilter('openedStation')
      removeFilterDashboardOpt('openedStation')
      await stationsStore.getListStations()
    }

    const removeAdvanceRequestedFilter = async () => {
      stationsStore.removeFilter('advanceRequested')
      removeFilterDashboardOpt('advanceRequested')
      await stationsStore.getListStations()
    }

    const removeAdvancePaidFilter = async () => {
      stationsStore.removeFilter('advancePaid')
      removeFilterDashboardOpt('advancePaid')
      await stationsStore.getListStations()
    }

    const removeStatusFilter = async () => {
      stationsStore.removeFilter('status')
      removeFilterDashboardOpt('status')
      await stationsStore.getListStations()
    }
    const removeOurTeamFilter = async () => {
      stationsStore.removeFilter('ourTeam')
      removeFilterDashboardOpt('ourTeam')
      await stationsStore.getListStations()
    }

    const removeStationTypeFilter = async () => {
      stationsStore.removeFilter('stationType')
      removeFilterDashboardOpt('stationType')
      await stationsStore.getListStations()
    }

    const dateFilter = computed(() => {
      if (!listStationsQuery) return
      return {
        text: `${format(
          addDays(new Date(listStationsQuery.value.startDate), 1),
          'dd/MM/yyyy'
        )} - ${format(new Date(listStationsQuery.value.endDate), 'dd/MM/yyyy')}`
      }
    })

    const unitsFilter = computed(() => {
      if (!listStationsQuery) return
      return listStationsQuery.value.healthUnits?.map((u) => ({
        id: u,
        text: healthUnitsMap.value.get(+u)
      }))
    })

    const hoursFilter = computed(() => {
      if (!listStationsQuery) return

      const startHours = listStationsQuery.value.startHour
        ? listStationsQuery.value.startHour.split(',')
        : []
      const endHours = listStationsQuery.value.endHour
        ? listStationsQuery.value.endHour.split(',')
        : []

      if (startHours.length !== endHours.length) return

      return startHours.map((startHour, i) => {
        return { text: `${startHour}-${endHours[i]}` }
      })
    })

    const schedulesFilter = computed(() => {
      if (!listStationsQuery) return
      return listStationsQuery.value.schedules?.map((u) => ({
        id: u,
        text: schedulesMap.value.get(+u)
      }))
    })

    const valueFilter = computed(() => {
      if (
        !listStationsQuery ||
        (!listStationsQuery.value.valueMin && !listStationsQuery.value.valueMax)
      )
        return
      if (listStationsQuery.value.valueMin && listStationsQuery.value.valueMax)
        return `Valor do plantão (${listStationsQuery.value.valueMin} até ${listStationsQuery.value.valueMax})`

      if (listStationsQuery.value.valueMin)
        return `Valor do plantão (Maior ou igual a ${listStationsQuery.value.valueMin})`

      if (listStationsQuery.value.valueMax)
        return `Valor do plantão (Menor ou igual a ${listStationsQuery.value.valueMax})`

      return ''
    })

    const paymentValueFilter = computed(() => {
      if (
        !listStationsQuery ||
        (!listStationsQuery.value.paymentValueMin && !listStationsQuery.value.paymentValueMax)
      )
        return
      if (listStationsQuery.value.paymentValueMin && listStationsQuery.value.paymentValueMax)
        return `Valor do plantão combinado (${listStationsQuery.value.paymentValueMin} até ${listStationsQuery.value.paymentValueMax})`

      if (listStationsQuery.value.paymentValueMin)
        return `Valor do plantão combinado (Maior ou igual a ${listStationsQuery.value.paymentValueMin})`

      if (listStationsQuery.value.paymentValueMax)
        return `Valor do plantão combinado (Menor ou igual a ${listStationsQuery.value.paymentValueMax})`

      return ''
    })

    const checkinFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.withCheckin === undefined) return
      return parseStringToBool(listStationsQuery.value.withCheckin) ? 'Com checkin' : 'Sem checkin'
    })

    const checkoutFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.withCheckout === undefined) return
      return parseStringToBool(listStationsQuery.value.withCheckout)
        ? 'Com checkout'
        : 'Sem checkout'
    })

    const inCashFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.inCash === undefined) return
      return parseStringToBool(listStationsQuery.value.inCash)
        ? 'Pagamento a vísta'
        : 'Pagamento sem ser a vísta'
    })

    const advanceRequestedFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.advanceRequested === undefined) return
      return parseStringToBool(listStationsQuery.value.advanceRequested)
        ? 'Antecipação solicitada'
        : 'Antecipação não solicitada'
    })

    const advancePaidFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.advancePaid === undefined) return
      return parseStringToBool(listStationsQuery.value.advancePaid)
        ? 'Antecipação paga'
        : 'Antecipação não paga'
    })

    const ourTeamFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.ourTeam === undefined) return
      return parseStringToBool(listStationsQuery.value.ourTeam)
        ? 'Nossa equipe'
        : 'Não é nossa equipe'
    })

    const openedStationFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.openedStation === undefined) return
      return parseStringToBool(listStationsQuery.value.openedStation)
        ? 'Vaga aberta'
        : 'Vaga preenchida'
    })

    const statusFilter = computed(() => {
      if (!listStationsQuery || listStationsQuery.value.status === undefined) return

      return listStationsQuery.value.status === 'waiting'
        ? 'Plantão pendente'
        : 'Plantão confirmado'
    })

    const stationTypeFilter = computed(() => {
      if (
        !listStationsQuery ||
        listStationsQuery.value.stationType === undefined ||
        !listStationsQuery.value.stationType?.length
      )
        return

      return `Plantão do tipo: ${listStationsQuery.value.stationType
        ?.map((st) => st.toLowerCase())
        .join(', ')}`
    })

    return {
      dateFilter,
      removeUnitsFilter,
      unitsFilter,
      hoursFilter,
      removeHoursFilter,
      schedulesFilter,
      removeSchedulesFilter,
      valueFilter,
      removeValueFilter,
      paymentValueFilter,
      removePaymentValueFilter,
      openedStationFilter,
      checkoutFilter,
      checkinFilter,
      inCashFilter,
      advanceRequestedFilter,
      advancePaidFilter,
      ourTeamFilter,
      statusFilter,
      stationTypeFilter,
      removeCheckinFilter,
      removeCheckoutFilter,
      removeInCashFilter,
      removeAdvanceRequestedFilter,
      removeAdvancePaidFilter,
      removeStatusFilter,
      removeStationTypeFilter,
      removeOurTeamFilter,
      removeOpenedStationFilter
    }
  }
}
</script>

<style>
.filter-btn {
  @apply flex gap-2 items-center cursor-pointer py-2 px-3 border border-gray-300 rounded-full text-gray-500 font-normal h-[40px];
}
</style>
