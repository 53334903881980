import { defineStore } from 'pinia'
import Network from '@/Network'
import { CreateSchedule } from '@/Network/Types/Requests/ScheduleRequests '
import {
  ListAllSchedulesResponse,
  ListSchedulesResponse,
  ScheduleResponse,
  PositionResponse
} from '@/Network/Types/Responses/ScheduleUnitResponses'
import { SelectOption } from '@/types'
import { useToast } from 'vue-toastification'

export type ScheduleState = {
  schedulesAdminAccess: SelectOption[]
  isLoadingSelectFetch: boolean
  schedulesForPage: ListAllSchedulesResponse[]
  isLoadingSchedulesForPage: boolean

  schedules: ListSchedulesResponse | undefined
  listSchedules: {
    title: string
    value: string
    timezone?: string
    subtitle?: string
    positions: PositionResponse[]
  }[]
  schedulesMap: Map<number, string>
  isLoading: boolean
}

export const useScheduleStore = defineStore('schedule', {
  state: (): ScheduleState => ({
    isLoadingSelectFetch: false,
    schedulesAdminAccess: [],
    schedulesForPage: [],
    isLoadingSchedulesForPage: false,

    schedules: undefined,
    listSchedules: [],
    schedulesMap: new Map(),
    isLoading: false
  }),
  actions: {
    async getSchedulesForPage() {
      const toast = useToast()
      try {
        this.isLoadingSchedulesForPage = true
        const data = await Network.schedule.listAll()
        this.schedulesForPage = data
      } catch (error) {
        toast.error('Erro ao buscar escalas, fale com um administrador.')
      } finally {
        this.isLoadingSchedulesForPage = false
      }
    },
    async getScheduleOptions(adminView: boolean = false) {
      const toast = useToast()

      try {
        this.isLoadingSelectFetch = true
        if (adminView) {
          const response = await Network.schedule.listAdminAccess()
          this.schedulesAdminAccess = response
        }
      } catch (error) {
        toast.error('Erro ao buscar escalas, fale com um administrador.')
      } finally {
        this.isLoadingSelectFetch = false
      }
    },
    async getAllSchedules(): Promise<void> {
      try {
        this.isLoading = true
        const data = await Network.schedule.list()
        this.schedules = data
        this.listSchedules = data.data.map((schedule) => {
          this.schedulesMap.set(+schedule.id, schedule.name)
          return {
            title: schedule.name,
            value: String(schedule.id),
            timezone: schedule.timezone,
            positions: schedule.positions
          }
        })
      } catch (error) {
        console.error('Erro ao obter schedules:', error)
        this.schedules = { count: 0, data: [] }
        this.listSchedules = []
      } finally {
        this.isLoading = false
      }
    },
    async create({ healthUnitId, payload }: { healthUnitId: number; payload: CreateSchedule }) {
      await Network.schedule.create(healthUnitId, payload)
      await this.getSchedulesForPage()
    },
    async remove(scheduleId: number): Promise<void> {
      await Network.schedule.remove(scheduleId)
      await this.getSchedulesForPage()
    },
    async update({
      payload,
      scheduleId
    }: {
      scheduleId: number
      payload: CreateSchedule
    }): Promise<void> {
      await Network.schedule.update(scheduleId, payload)
      await this.getSchedulesForPage()
    },
    async setSchedulesByHealthUnit(healthUnitId: number | null) {
      if (!healthUnitId) {
        this.listSchedules = []
        return
      }
      await this.getAllSchedules()
      const schedules = this.schedules?.data.filter((h) => h.healthUnit.id === healthUnitId) || []
      this.listSchedules = schedules.map((h) => ({
        title: h.name,
        value: String(h.id),
        timezone: h.timezone,
        positions: h.positions
      }))
    },
    async setSchedulesByHealthUnits(healthUnitIds: number[] | null) {
      if (!healthUnitIds?.length) {
        this.listSchedules = []
        return
      }
      await this.getAllSchedules()
      const schedules =
        this.schedules?.data.filter((h) => healthUnitIds.includes(h.healthUnit.id)) || []
      this.listSchedules = schedules.map((h) => ({
        title: h.name,
        value: String(h.id),
        timezone: h.timezone,
        subtitle: h.healthUnit.name,
        positions: h.positions
      }))
    },
    async getScheduleById(scheduleId: number): Promise<ScheduleResponse> {
      const data = await Network.schedule.getById(scheduleId)
      return data
    }
  }
})
