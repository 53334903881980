<template>
  <modal-vue @close="$emit('closeModal')" :modalActive="showModal" :hasFooterBorder="true">
    <template #header>
      <div class="text-left px-[16px] flex gap-1">
        <span class="font-bold">#{{ infos.stationId }}</span>
        -
        <span class="font-bold">Dados do plantão</span>
      </div>
    </template>
    <div class="station-form-container">
      <PricesForm
        v-model:doctor="doctor"
        v-model:value="value"
        v-model:inCash="inCash"
        v-model:advanceRequested="advanceRequested"
        v-model:advancePaid="advancePaid"
        v-model:modified-value="modifiedValue"
        :company="infos.companyId"
        :originalValue="infos.stationValue"
        :urlConviteDoctor="infos.urlConviteDoctor"
        :originalDoctor="infos.doctorId"
        :update-form="true"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <HourAndDateForm
        v-model:date="date"
        v-model:timezone="timezone"
        v-model:startHour="startHour"
        v-model:endHour="endHour"
        :isUpdateMode="true"
      />
      <div v-if="infos.position && infos.position.name" class="text-left">
        <span class="station-form-category-title">Posição: </span>
        <span class="text-left">{{ infos.position.name }}</span>
      </div>
      <StationDetailsForm v-model:station-type="stationType" v-model:our-team="ourTeam" />
      <div class="w-full border-b-2 mb-4"></div>
      <ObservationForm v-model:observations="observations" />

      <DoctorChecks
        @saveChecks="updateChecks"
        v-if="infos.doctorId"
        :infos="infos.checks ?? []"
        :health-unit-latitude="infos?.healthUnitLatitude"
        :health-unit-longitude="infos?.healthUnitLongitude"
      />
    </div>

    <template #footer>
      <div class="flex gap-5 justify-between mt-4 px-3">
        <GhostButton
          class="!text-red-500 hover:!text-red-500 hover:!bg-red-100"
          :prepend-icon="mdiMinusCircle"
          :loading="isLoading"
          @click="showModalDelete = true"
          text="EXCLUIR PLANTÃO"
          height="52px"
          width="180px"
        />
        <filled-button
          :loading="isLoading"
          @click="handleSubmit"
          text="Salvar alterações"
          height="52px"
          width="180px"
        />
      </div>
    </template>
  </modal-vue>
  <modal-vue @close="showModalDelete = false" :modalActive="showModalDelete">
    <template #header>
      <p class="font-bold">Excluir Plantão</p>
    </template>
    <p class="text-base font-normal">
      Tem certeza que deseja excluir o plantão?<br />Essa ação não poderá ser desfeita!
    </p>
    <template #footer>
      <div class="flex justify-center gap-4">
        <OutlinedButton width="119px" @click="showModalDelete = false" text="Cancelar" />
        <filled-button
          :loading="isLoading"
          text="Excluir"
          width="119px"
          @click="handleDeleteStation"
        />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import { ref, defineEmits, PropType, onMounted, onBeforeUnmount, onUpdated, watch } from 'vue'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import PricesForm from '../createStation/components/form/prices.form.vue'
import HourAndDateForm from '../createStation/components/form/hours.and.date.form.vue'
import StationDetailsForm from '../createStation/components/form/station.details.form.vue'
import ObservationForm from '../createStation/components/form/observation.form.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlinedButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import {
  HistoryDate,
  Observation,
  UpdateStationRequest
} from '@/Network/Types/Requests/StationRequests'
import NetworkService from '@/Network/index'
import { useToast } from 'vue-toastification'
import { useStationStore } from '@/pinia-store/useStationStore'
import {
  getHistoriesRequest,
  getStartDateAndEndDate,
  StationToUpdate
} from '../createStation/helpers'
import DoctorChecks from './components/doctor.checks.vue'
import { mdiMinusCircle } from '@mdi/js'

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  infos: {
    type: Object as PropType<StationToUpdate>,
    required: true
  }
})
const toast = useToast()

// PRICE CATEGORY
const advanceRequested = ref()
const advancePaid = ref()
const doctor = ref()
const value = ref()
const inCash = ref()
const modifiedValue = ref()

// OBSERVATION CATEGORY
const observations = ref<Observation[]>([])

// DATE CATEGORY
const date = ref<string>('')
const startHour = ref()
const endHour = ref()
const timezone = ref()

// DETAILS CATEGORY
const ourTeam = ref()
const stationType = ref()

// HISTORY CATEGORY
const histories = ref<HistoryDate[]>()

const isLoading = ref(false)

const showModalDelete = ref()

const stationStore = useStationStore()
const emit = defineEmits(['closeModal'])
const mapTimezones = {
  '-02:00': 'America/Noronha',
  '-03:00': 'America/Belem',
  '-04:00': 'America/Manaus',
  '-05:00': 'America/Rio_Branco'
}

watch(timezone, (_, oldValue) => {
  if (!oldValue) return
  const { startDate, endDate } = getStartDateAndEndDate({
    date: date.value,
    startHour: startHour.value,
    endHour: endHour.value,
    timezone: oldValue
  })
  date.value = format(utcToZonedTime(startDate, timezone.value), 'dd/MM/yyyy')
  startHour.value = format(utcToZonedTime(startDate, timezone.value), 'HH:mm')
  endHour.value = format(utcToZonedTime(endDate, timezone.value), 'HH:mm')
})

onUpdated(async () => {
  timezone.value = props.infos.timezone ?? undefined
  if (!props.showModal || !props.infos) return
  doctor.value = props.infos.doctorId ? String(props.infos.doctorId) : undefined
  const tz = mapTimezones[props.infos.timezone]
  const dateTimezoned = utcToZonedTime(props.infos.date, tz)
  date.value = format(dateTimezoned, 'dd/MM/yyyy')
  startHour.value = format(utcToZonedTime(props.infos.startHour || '', tz), 'HH:mm')
  endHour.value = format(utcToZonedTime(props.infos.endHour || '', tz), 'HH:mm')
  ourTeam.value = !!props.infos.ourTeam
  stationType.value = props.infos.stationType
  value.value = props.infos.value
  inCash.value = !!props.infos.inCash
  advancePaid.value = !!props.infos.advancePaid
  advanceRequested.value = !!props.infos.advanceRequested
  modifiedValue.value = !!props.infos.modifiedValue
  observations.value = props.infos.observations ?? []
})

const handleSubmit = async () => {
  try {
    if (!props.infos) return
    isLoading.value = true

    const { startDate, endDate } = getStartDateAndEndDate({
      date: date.value,
      startHour: startHour.value,
      endHour: endHour.value,
      timezone: timezone.value
    })

    const originChecks = getHistoriesRequest(
      histories.value || props.infos.checks || [],
      startDate,
      endDate
    )

    const payload: UpdateStationRequest = {
      doctorId: doctor.value ? Number(doctor.value) : undefined,
      value: value.value ? Number(value.value) : undefined,
      inCash: inCash.value,
      advancePaid: advancePaid.value,
      advanceRequested: advanceRequested.value,
      config: {
        date: startDate,
        startHour: startDate,
        endHour: endDate,
        timezone: timezone.value
      },
      observations: observations.value,
      stationType: stationType.value,
      ourTeam: ourTeam.value,
      histories: originChecks || [],
      modifiedValue: modifiedValue.value
    }

    const formData = new FormData()
    formData.append('data', JSON.stringify(payload))

    payload.histories.forEach((history, index) => {
      if (history.evidence && history.evidence.length > 0) {
        history.evidence.forEach((file) => {
          formData.append(`historyEvidence[${index}][]`, file)
        })
      }
    })

    await NetworkService.station.update(props.infos.stationId, formData)
    toast.success('Plantão editado com sucesso')
    await stationStore.getListStations()
    emit('closeModal')
  } catch (error) {
    toast.error('Ops, não foi possível processar a requisição, tente novamente')
    emit('closeModal')
  } finally {
    isLoading.value = false
  }
}

const handleDeleteStation = async () => {
  try {
    // to-do: Verificar os return vazios
    if (!props.infos) return
    isLoading.value = true
    await NetworkService.station.delete(props.infos.stationId)
    toast.success('Plantão removido com sucesso')
    await stationStore.getListStations()
    showModalDelete.value = false
    emit('closeModal')
  } catch (error) {
    toast.error('Ops, não foi possível processar a requisição, tente novamente')
    showModalDelete.value = false
    emit('closeModal')
  } finally {
    isLoading.value = false
  }
}

const updateChecks = (h: HistoryDate[]) => {
  histories.value = h
}

// Função para fechar o modal ao pressionar ESC
const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && props.showModal) {
    emit('closeModal')
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})
</script>
