export enum PhoneType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  PARTICULAR = 'PARTICULAR',
  OTHER = 'OTHER'
}

export type CreateHealthUnit = {
  avatarUrl?: string
  email?: string
  cnpj?: string
  profile?: string
  quantityColaborators?: number
  generalManager?: string
  technicalManager?: string
  name?: string
  linkWaze?: string
  mainPhone?: {
    phoneNumber: string
    phoneType: PhoneType
  }
  mainAddress?: {
    postalCode: string
    state: string
    city: string
    street: string
    number: string
    neighborhood: string
    complement?: string
    latitude: number
    longitude: number
  }
}

export type FiltersHealthUnits = {
  search?: string
  names?: string
  cnpjs?: string
  ufs?: string
  companies?: string
  limit?: number
  offset?: number
  page?: number
  // companyId: number
}
