<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" isFilterModal>
    <template #header>
      <p class="font-bold">Editar escala</p>
    </template>
    <v-progress-linear v-if="!data || isLoading" indeterminate color="primary" />
    <div v-else class="flex flex-col">
      <v-text-field
        v-model="data.scheduleName"
        label="Nome da Escala*"
        class="w-full"
        color="primary20"
        variant="outlined"
        :errorMessages="errors.scheduleName"
      />

      <v-autocomplete
        v-model="data.timezone"
        class="w-full"
        color="primary20"
        label="Fuso Horário"
        theme="light"
        variant="outlined"
        :items="timezones"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.title"
            :subtitle="item?.raw?.subtitle"
          ></v-list-item>
        </template>
      </v-autocomplete>

      <v-checkbox
        v-model="data.requiredCheckNotOurTeam"
        density="compact"
        label="Obrigar check para externos"
        color="primary"
        hide-details
      />
      <v-checkbox
        v-model="data.requiredCheckOurTeam"
        density="compact"
        label="Obrigar check para nosso time"
        color="primary"
        hide-details
      />
    </div>
    <template #footer>
      <div class="flex justify-between gap-2 w-full">
        <outline-button width="119px" @click="handleToggleModal" text="Cancelar" />
        <filled-button
          :disabled="isLoading"
          :loading="isLoading"
          text="Editar"
          width="119px"
          @click="handleSubmit"
        />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { timezones } from '@/hooks/useCommon'
import { useToast } from 'vue-toastification'
const props = defineProps({
  scheduleId: {
    type: Number,
    required: true
  }
})

const modalName = EModals.UPDATE_SCHEDULE
const { modals } = storeToRefs(useModalStore())
const scheduleStore = useScheduleStore()
const modalStore = useModalStore()
const toast = useToast()

const data = ref<{
  timezone: string
  scheduleName: string
  healthUnitId: number
  requiredCheckOurTeam?: boolean
  requiredCheckNotOurTeam?: boolean
}>()
const errors = ref<{ timezone: string; scheduleName: string; healthUnitId: string }>({
  healthUnitId: '',
  scheduleName: '',
  timezone: ''
})

const isLoading = ref(false)

onMounted(async () => {
  console.log('MountedModal')
  if (!props.scheduleId) return
  clearErrs()

  try {
    isLoading.value = true
    const schedule = await scheduleStore.getScheduleById(props.scheduleId)
    data.value = {
      healthUnitId: schedule.healthUnit.id,
      scheduleName: schedule.name,
      timezone: schedule.timezone,
      requiredCheckNotOurTeam: schedule.requiredCheckNotOurTeam,
      requiredCheckOurTeam: schedule.requiredCheckOurTeam
    }
  } finally {
    isLoading.value = false
  }
})

const hasError = () => {
  let hasError = false
  if (!data.value?.scheduleName) {
    errors.value.scheduleName = 'Informe um nome para escala'
    hasError = true
  }
  if (!data.value?.healthUnitId) {
    errors.value.healthUnitId = 'Selecione um hospital'
    hasError = true
  }

  return hasError
}

const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
  data.value = undefined
  clearErrs()
}

const handleSubmit = async () => {
  if (!data.value || hasError()) {
    toast.error('Dados inválidos ou não preenchidos')
    return
  }

  try {
    isLoading.value = true

    const { healthUnitId, scheduleName, timezone, requiredCheckNotOurTeam, requiredCheckOurTeam } =
      data.value
    const payload = {
      healthUnitId: Number(healthUnitId),
      payload: { name: scheduleName!, timezone, requiredCheckNotOurTeam, requiredCheckOurTeam },
      scheduleId: props.scheduleId
    }
    await scheduleStore.update(payload)

    modalStore.alertDescription = 'Escala editada com sucesso!'
    modalStore.toggleModal(modalName)
    modalStore.toggleShowAlert()
  } finally {
    isLoading.value = false
  }
}

const clearErrs = () => {
  errors.value.healthUnitId = ''
  errors.value.scheduleName = ''
  errors.value.timezone = ''
}
</script>
