<template>
  <div class="station-form-category-container">
    <span class="station-form-category-title">Horário e data</span>
    <div class="station-form-category-content">
      <div class="station-form-category-columns">
        <DatePicker
          @update:model-value="setDate"
          :model-value="date ? new Date(date.split('/').reverse().join('-') + 'T12:00:00') : null"
          :masks="{ input: `DD/MM/YYYY` }"
        >
          <template #default="{ inputValue, inputEvents }">
            <v-text-field
              @update:model-value="setDate"
              :model-value="date"
              label="Dia"
              :prepend-inner-icon="mdiCalendar"
              :value="inputValue"
              v-on="inputEvents"
              class="w-full"
              color="primary20"
              variant="outlined"
              density="comfortable"
            />
          </template>
        </DatePicker>
        <v-autocomplete
          @update:model-value="setTimezone"
          :auto-select-first="true"
          :items="timezones"
          :model-value="timezone"
          class="w-full"
          color="primary20"
          label="Fuso Horário"
          theme="light"
          variant="outlined"
          density="comfortable"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :title="item?.raw?.title" :subtitle="item?.raw?.subtitle" />
          </template>
        </v-autocomplete>
      </div>
      <div class="station-form-category-columns">
        <v-text-field
          @update:model-value="setStartHour"
          :model-value="startHour"
          v-maska="'##:##'"
          label="Início"
          class="w-full"
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
        <v-text-field
          @update:model-value="setEndHour"
          :model-value="endHour"
          v-maska="'##:##'"
          label="Fim"
          class="w-full"
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
      </div>
      <div v-if="!isUpdateMode">
        <div class="station-form-category-columns justify-center mb-3">
          <v-radio-group
            @update:model-value="setConfigurationRepetition"
            :model-value="configurationRepetition"
            inline
            class="flex justify-center"
          >
            <v-radio label="Apenas uma vez" color="primary" value="no-repeat"></v-radio>
            <v-radio label="Repetir" color="primary" value="daily"></v-radio>
            <!-- <v-radio label="Personalizado" color="primary" value="custom"></v-radio> -->
          </v-radio-group>
        </div>
        <v-row dense v-if="configurationRepetition === 'no-repeat'">
          <v-col cols="5">
            <select-positions
              :key="`sposition-${schedule || 0}`"
              :schedule-id="schedule"
              v-model="positionsByRowStations[i]"
              @onSelect="($e) => onSelectPositionNoRepeat($e, i)"
            />
          </v-col>
          <v-col cols="7">
            <DatePicker
              @update:model-value="setFinishedAtUntil"
              :model-value="finishedAtUntil"
              :masks="{ input: `DD MMM YYYY` }"
            >
              <template #default="{ inputValue, inputEvents }">
                <v-text-field
                  v-if="finishedAt === 'until'"
                  placeholder="Range"
                  :prepend-inner-icon="mdiCalendar"
                  :value="inputValue"
                  v-on="inputEvents"
                  density="comfortable"
                  class="w-full"
                  color="primary20"
                ></v-text-field>
              </template>
            </DatePicker>
            <v-text-field
              v-if="finishedAt === 'occurrence'"
              @update:model-value="setFinishedAtAfter"
              :model-value="finishedAtAfter"
              type="number"
              density="comfortable"
              placeholder="Nº de ocorrências"
              class="w-full"
              color="primary20"
              suffix="Ocorrencias"
            />
          </v-col>
        </v-row>
        <!-- <v-row dense v-if="configurationRepetition === 'custom'">
          <v-col cols="12">
            <v-autocomplete
              class="w-full"
              @update:model-value="setValueRepetition"
              :model-value="valueRepetition"
              :items="optionsRepeatStation"
              color="primary20"
              :disabled="String(configurationRepetition) !== 'custom'"
              density="comfortable"
              label="Repetições"
              multiple
              chips
              :error-messages="repetitionErr"
            />
          </v-col>
        </v-row> -->
        <v-content dense v-if="configurationRepetition !== 'no-repeat'">
          <v-row class="gap-2">
            <v-col cols="12" class="text-left">
              <span class="text-sm"> Quantidade de plantões: </span>
            </v-col>
            <v-row no-gutters class="flex-nowrap gap-2 min-h-36">
              <v-col class="stations-cols">
                <v-card
                  @click="addStationInWeekDay('segunda')"
                  class="card-station card-header"
                  title="Segunda"
                  subtitle="➕"
                  link
                />
                <v-card
                  v-for="(v, i) in new Array(stationsCountByWeekDays.segunda)"
                  :key="'segunda-' + i"
                  @click="removeStationInWeekDay('segunda')"
                  class="card-station card-new-station"
                  title="Vaga"
                  subtitle="➖"
                  link
                />
              </v-col>
              <v-col class="stations-cols">
                <v-card
                  @click="addStationInWeekDay('terca')"
                  class="card-station card-header"
                  title="Terça"
                  subtitle="➕"
                  link
                />
                <v-card
                  v-for="(v, i) in new Array(stationsCountByWeekDays.terca)"
                  :key="'terca-' + i"
                  @click="removeStationInWeekDay('terca')"
                  class="card-station card-new-station"
                  title="Vaga"
                  subtitle="➖"
                  link
                />
              </v-col>
              <v-col class="stations-cols">
                <v-card
                  @click="addStationInWeekDay('quarta')"
                  class="card-station card-header"
                  title="Quarta"
                  subtitle="➕"
                  link
                />
                <v-card
                  v-for="(v, i) in new Array(stationsCountByWeekDays.quarta)"
                  :key="'quarta-' + i"
                  @click="removeStationInWeekDay('quarta')"
                  class="card-station card-new-station"
                  title="Vaga"
                  subtitle="➖"
                  link
                />
              </v-col>
              <v-col class="stations-cols">
                <v-card
                  @click="addStationInWeekDay('quinta')"
                  class="card-station card-header"
                  title="Quinta"
                  subtitle="➕"
                  link
                />
                <v-card
                  v-for="(v, i) in new Array(stationsCountByWeekDays.quinta)"
                  :key="'quinta-' + i"
                  @click="removeStationInWeekDay('quinta')"
                  class="card-station card-new-station"
                  title="Vaga"
                  subtitle="➖"
                  link
                />
              </v-col>
              <v-col class="stations-cols">
                <v-card
                  @click="addStationInWeekDay('sexta')"
                  class="card-station card-header"
                  title="Sexta"
                  subtitle="➕"
                  link
                />
                <v-card
                  v-for="(v, i) in new Array(stationsCountByWeekDays.sexta)"
                  :key="'sexta-' + i"
                  @click="removeStationInWeekDay('sexta')"
                  class="card-station card-new-station"
                  title="Vaga"
                  subtitle="➖"
                  link
                />
              </v-col>
              <v-col class="stations-cols">
                <v-card
                  @click="addStationInWeekDay('sabado')"
                  class="card-station card-header"
                  title="Sábado"
                  subtitle="➕"
                  link
                />
                <v-card
                  v-for="(v, i) in new Array(stationsCountByWeekDays.sabado)"
                  :key="'sabado-' + i"
                  @click="removeStationInWeekDay('sabado')"
                  class="card-station card-new-station"
                  title="Vaga"
                  subtitle="➖"
                  link
                />
              </v-col>
              <v-col class="stations-cols">
                <v-card
                  @click="addStationInWeekDay('domingo')"
                  class="card-station card-header"
                  title="Domingo"
                  subtitle="➕"
                  link
                />
                <v-card
                  v-for="(v, i) in new Array(stationsCountByWeekDays.domingo)"
                  :key="'domingo-' + i"
                  @click="removeStationInWeekDay('domingo')"
                  class="card-station card-new-station"
                  title="Vaga"
                  subtitle="➖"
                  link
                />
              </v-col>
              <v-col class="stations-cols">
                <v-card class="card-position mb-2" title="Posição" disabled />
                <v-card
                  v-for="(v, i) in new Array(biggerCountFromWeekDays)"
                  :key="'position-' + i"
                  class="card-position mb-2"
                >
                  <select-positions
                    class="select-position-bulk ml-2"
                    :key="`sposition-${schedule || 0}`"
                    :schedule-id="schedule"
                    v-model="positionsByRowStations[i]"
                    @onSelect="($e) => onSelectPosition($e, i)"
                    variant="plain"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-content>
        <v-row dense v-if="configurationRepetition !== 'no-repeat'">
          <v-col cols="5">
            <v-select
              label="Repetir"
              @update:model-value="setFinishedAt"
              :items="finishedAtOptions"
              :model-value="finishedAt"
              color="primary20"
              density="comfortable"
            />
          </v-col>
          <v-col cols="7">
            <DatePicker
              @update:model-value="setFinishedAtUntil"
              :model-value="finishedAtUntil"
              :masks="{ input: `DD MMM YYYY` }"
            >
              <template #default="{ inputValue, inputEvents }">
                <v-text-field
                  v-if="finishedAt === 'until'"
                  placeholder="Range"
                  :prepend-inner-icon="mdiCalendar"
                  :value="inputValue"
                  v-on="inputEvents"
                  density="comfortable"
                  class="w-full"
                  color="primary20"
                ></v-text-field>
              </template>
            </DatePicker>
            <v-text-field
              v-if="finishedAt === 'occurrence'"
              @update:model-value="setFinishedAtAfter"
              :model-value="finishedAtAfter"
              type="number"
              density="comfortable"
              placeholder="Nº de ocorrências"
              class="w-full"
              color="primary20"
              suffix="Ocorrencias"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, defineEmits, reactive, computed } from 'vue'
import { DatePicker } from 'v-calendar'
import { mdiCalendar } from '@mdi/js'
import { timezones } from '@/hooks/useCommon'
import SelectPositions from './select.positions.vue'
import { ref } from 'vue'
import { format } from 'date-fns'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { finishedAtOptions } from '../../helpers'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps([
  'isUpdateMode',
  'date',
  'timezone',
  'startHour',
  'endHour',
  'configurationRepetition',
  'valueRepetition',
  'repetitionDays',
  'finishedAt',
  'finishedAtUntil',
  'finishedAtAfter',
  'repetitionErr',
  'schedule'
])
const emit = defineEmits([
  'update:date',
  'update:timezone',
  'update:startHour',
  'update:endHour',
  'update:configurationRepetition',
  'update:valueRepetition',
  'update:repetitionDays',
  'update:finishedAt',
  'update:finishedAtUntil',
  'update:finishedAtAfter',
  'update:positionsByRowStations'
])

enum WeekDaysNumbers {
  'segunda' = '1',
  'terca' = '2',
  'quarta' = '3',
  'quinta' = '4',
  'sexta' = '5',
  'sabado' = '6',
  'domingo' = '0'
}

const stationsCountByWeekDays = reactive({
  segunda: 0,
  terca: 0,
  quarta: 0,
  quinta: 0,
  sexta: 0,
  sabado: 0,
  domingo: 0
})

const positionsByRowStations = ref<Array<number | null>>([])

const biggerCountFromWeekDays = computed(() => {
  return Math.max(...Object.values(stationsCountByWeekDays))
})

const setDate = (v: Date | string) => {
  if (!v) return
  const formattedDate =
    typeof v === 'string' ? v : format(new Date(v.setHours(12, 0, 0, 0)), 'dd/MM/yyyy')
  emit('update:date', formattedDate)
}
const setTimezone = (v: string) => emit('update:timezone', v)
const setStartHour = (v: string) => emit('update:startHour', v)
const setEndHour = (v: string) => emit('update:endHour', v)
const setConfigurationRepetition = (v: string) => {
  emit('update:configurationRepetition', v)
}

const setFinishedAt = (v: string) => {
  emit('update:finishedAt', v)
}

const setFinishedAtUntil = (v: string) => emit('update:finishedAtUntil', v)
const setFinishedAtAfter = (v: string) => emit('update:finishedAtAfter', v)

function buildRepetionDays(weekDays: typeof stationsCountByWeekDays): Record<string, string> {
  // Percorre as chaves do objeto (segunda, terca, etc.)
  return Object.keys(weekDays).reduce(
    (acc, day) => {
      // day é do tipo 'segunda' | 'terca' | ...
      // dayNumber é a string do enum, ex: 'segunda' -> '1'
      const dayNumber = WeekDaysNumbers[day as keyof typeof WeekDaysNumbers]

      // Salva no acumulador a chave (dayNumber) com o valor em string
      acc[dayNumber] = String(weekDays[day as keyof typeof weekDays])
      return acc
    },
    {} as Record<string, string>
  )
}

const setRepetitionDays = () => {
  const repetionDays = buildRepetionDays(stationsCountByWeekDays)
  console.log('update:repetitionDays', repetionDays)
  emit('update:repetitionDays', repetionDays)
}

const addStationInWeekDay = (weekDay) => {
  stationsCountByWeekDays[weekDay] += 1
  setRepetitionDays()
}

const removeStationInWeekDay = (weekDay) => {
  stationsCountByWeekDays[weekDay] -= 1
  positionsByRowStations.value.pop()
  // positionsByRowStations.value = popped
  setRepetitionDays()
}

const onSelectPosition = (e, rowIndex) => {
  //adicionar no objeto o ID da position pra vincular na vaga.
  positionsByRowStations.value[rowIndex] = e
  console.log(positionsByRowStations.value)
  setRepetitionDays()
  emit('update:positionsByRowStations', positionsByRowStations.value)
}

const onSelectPositionNoRepeat = (e, rowIndex) => {
  positionsByRowStations.value[rowIndex] = e
  //adicionar no objeto o ID da position pra vincular na vaga.
  emit('update:positionsByRowStations', [e])
}
</script>
<style lang="scss" scoped>
.card-header {
  background-color: #3da2b9;
  margin-bottom: 0.5rem;
}

.card-new-station {
  background-color: #f18f1b;
  margin-bottom: 0.5rem;
}

.card-position {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: lightgray;
  width: 88px;
  height: 56px;
}

.card-station {
  width: 88px;
  height: 56px;
}

.card-station :deep(.v-card-title),
.card-position :deep(.v-card-title) {
  font-size: 0.8rem;
  padding-top: 0.25rem;
  line-height: 1.5rem;
}

.card-station :deep(.v-card-subtitle),
.card-position :deep(.v-card-subtitle) {
  font-size: 0.8rem;
  line-height: 1rem;
}

.card-position :deep(.v-card-item) {
  padding: 0 0.6rem;
}

.card-station :deep(.v-card-item) {
  padding: 0 0.6rem;
}

.stations-cols {
  flex-grow: 0;
}

/* Usando o :deep() para aplicar o estilo dentro do escopo */
:deep(.w-full.select-position-bulk > div > div > div > div.v-field__field > label) {
  font-size: 0.8rem;
}
:deep(div.w-full.select-position-bulk > div > div > div > div.v-field__field > div > input) {
  font-size: 0.8rem;
}

:deep(div.w-full.select-position-bulk > div > div > div > div.v-field__append-inner) {
  font-size: 0.8rem;
}

:deep(
  div.w-full.select-position-bulk.ml-2 > div > div > div > div.v-field__field > div > div > span
) {
  font-size: 0.8rem;
}
</style>
