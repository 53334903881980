export enum ResourceType {
  Doctor = 'doctor',
  CompanyUser = 'company_user',
  User = 'user',
  HealthUnit = 'health_unit',
  Schedule = 'schedule',
  Position = 'position',
  Station = 'station',
  Company = 'company',
  Group = 'group',
  Platform = 'platform'
}

export enum PermissionType {
  Owner = 'owner',
  Admin = 'administrator',
  View = 'viewer',
  Member = 'direct_member',
  Remove = 'remove' // use only frontend
}

export type PermissionResponse = {
  id: number
  resource_type: ResourceType
  resource_id: number
  resource_relation: PermissionType
  subject_type: ResourceType
  subject_id: number
  subject_name: string
  subject_description?: string
}
