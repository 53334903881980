<!-- src/assets/icons/DownloadIcon.vue -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Linha inferior -->
    <path
      d="M5 20h14v-2H5v2z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <!-- Seta para baixo -->
    <path
      d="M12 2l-5 5h3v6h4V7h3l-5-5z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
