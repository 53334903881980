<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" :hasFooterBorder="false">
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong class="title font-20">Compartilhar "{{ metadata?.resourceName }}"</strong>
      </div>
    </template>

    <v-row dense v-if="isUnauthorize">
      <v-alert
        color="error"
        icon="$warning"
        title="Você não tem permissão para compartilhar este recurso..."
      ></v-alert>
    </v-row>
    <v-row dense v-else>
      <v-col :md="subjectsToShare.length ? 10 : 12" cols="12">
        <v-autocomplete
          v-model="subjectsToShare"
          :items="subjects"
          :filter-keys="['raw.title', 'raw.subtitle']"
          label="Adicionar Participantes ou Grupos"
          color="primary20"
          variant="outlined"
          density="compact"
          clear-on-select
          :menu-props="{ closeOnContentClick: true }"
          chips
          closable-chips
          multiple
          hide-details
          clearable
          :disabled="isLoading"
          :loading="isLoading"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              color="primary30"
              v-bind="props"
              :prepend-icon="item.raw.icon"
              :text="item.raw.title"
            />
          </template>

          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :prepend-icon="item.raw.icon"
              :subtitle="item.raw.subtitle"
              :title="item.raw.title"
            />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="subjectsToShare.length" cols="12" md="2">
        <v-autocomplete
          v-model="permission"
          auto-select-first
          :items="permissionNewUsers"
          item-title="label"
          item-value="value"
          color="primary20"
          density="compact"
          hide-details
          chips
          variant="outlined"
          required
        >
          <template v-slot:chip="{ props, item }">
            <span class="flex items-center text-sm" v-bind="props">
              {{ item.raw.label }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- <v-col>
        <check-box
          class="mt-2 mb-4 text-sm"
          v-model:checked="isEnableExternalShare"
          fieldId="external-share"
          label="Permitir compartilhar com usuários de outras empresas"
        />
      </v-col> -->

      <Transition name="slide-down" mode="out-in">
        <v-col class="mt-3" v-if="isShareContext">
          <v-textarea
            v-model="messageNotify"
            class="message-to-share"
            color="primary20"
            label="Mensagem"
            :disabled="!isNotifyShare"
          />
          <v-checkbox
            v-model="isNotifyShare"
            color="primary"
            fieldId="isNotify"
            label="Notificar Pessoas"
          />
        </v-col>
        <v-col v-else cols="12" class="mt-2">
          <div class="text-left">
            <div class="mt-2 h-[250px] overflow-auto">
              <div v-if="isLoading" class="flex items-center justify-center">
                <v-progress-circular color="primary" size="172" indeterminate />
              </div>

              <v-row v-else dense>
                <v-col v-if="permissionGroups.length" cols="12">
                  <strong class="title">Grupos com acesso</strong>
                  <v-list-item
                    v-for="subject in permissionGroups"
                    :key="subject.id"
                    class="pl-0 h-[60px]"
                    color="primary20"
                    :title="subject.subject_name"
                    :subtitle="subject.subject_description"
                  >
                    <template v-slot:prepend>
                      <v-avatar color="primary30">
                        <span class="text-md font-bold">{{
                          getInitalsName(subject.subject_name)
                        }}</span>
                      </v-avatar>
                    </template>
                    <template v-slot:append>
                      <v-select
                        v-model="subject.resource_relation"
                        :disabled="subject.resource_relation == 'owner'"
                        :items="permissionsForSelect"
                        item-title="label"
                        item-value="value"
                        color="primary20"
                        density="compact"
                        variant="plain"
                      >
                        <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props" :disabled="item.raw.disabled"></v-list-item>
                        </template>
                      </v-select>
                    </template>
                  </v-list-item>
                </v-col>
                <v-col v-else cols="12">
                  <strong class="title">Grupos com acesso</strong>
                  <div class="flex mt-4">
                    <CardNoSharedResource />
                  </div>
                </v-col>

                <v-col v-if="permissionUsers.length" cols="12">
                  <strong class="title">Pessoas com acesso</strong>
                  <v-list-item
                    v-for="subject in permissionUsers"
                    :key="subject.id"
                    class="pl-0 h-[60px]"
                    color="primary20"
                    :title="subject.subject_name"
                    :subtitle="subject.subject_description"
                  >
                    <template v-slot:prepend>
                      <v-avatar color="primary30">
                        <span class="text-md font-bold">{{
                          getInitalsName(subject.subject_name)
                        }}</span>
                      </v-avatar>
                    </template>
                    <template v-slot:append>
                      <v-select
                        v-model="subject.resource_relation"
                        :disabled="subject.resource_relation == 'owner'"
                        :items="permissionsForSelect"
                        item-title="label"
                        item-value="value"
                        color="primary20"
                        density="compact"
                        variant="plain"
                      >
                        <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props" :disabled="item.raw.disabled"></v-list-item>
                        </template>
                      </v-select>
                    </template>
                  </v-list-item>
                </v-col>
                <v-col v-else cols="12">
                  <strong class="title">Pessoas com acesso</strong>
                  <div class="flex mt-4">
                    <CardNoSharedResource />
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </Transition>
    </v-row>

    <template #footer>
      <Transition name="fade" mode="out-in">
        <v-row class="pt-3">
          <v-col cols="12" md="6">
            <ghost-button @click="handleToggleModal" width="100%" text="Cancelar" />
          </v-col>
          <v-col cols="12" md="6">
            <filled-button
              @click="handleSubmit"
              :text="isShareContext ? 'Enviar' : 'Concluido'"
              height="52px"
              width="100%"
              :disabled="isLoading || isUnauthorize"
            />
          </v-col>
        </v-row>
      </Transition>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { computed, onUpdated, ref } from 'vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useUserStore } from '@/pinia-store/useUsersStore'
import Network from '@/Network'
import { UpsertPermissionsRequest } from '@/Network/Types/Requests/PermissionRequests'
import {
  PermissionResponse,
  PermissionType,
  ResourceType
} from '@/Network/Types/Responses/PermissionResponses'
import { useToast } from 'vue-toastification'
import { DEFAULT_MESSAGES } from '@/hooks/useCommon'
import { mdiAccount, mdiAccountMultiple } from '@mdi/js'
import { useGroupStore } from '@/pinia-store/useGroupStore'
import CardNoSharedResource from '../Cards/CardNoSharedResource.vue'
import axios from 'axios'

/* ******** Modal Props and Actions Default *********** */
const modalName = EModals.SHARE
const { modals } = storeToRefs(useModalStore())
const modalStore = useModalStore()
const handleToggleModal = () => {
  clearFields()
  modalStore.toggleModal(modalName)
}
const toast = useToast()

const isUnauthorize = ref(false)

const userStore = useUserStore()
const groupStore = useGroupStore()
const { listUsers } = storeToRefs(userStore)
const { groups } = storeToRefs(groupStore)
const permissionsData = ref<PermissionResponse[]>([])

const permissionGroups = computed(() => {
  return permissionsData.value.filter(
    (permission) => permission.subject_type === ResourceType.Group
  )
})

const permissionUsers = computed(() => {
  return permissionsData.value.filter((permission) => permission.subject_type === ResourceType.User)
})

const originPermissionsDataMap = new Map<number, PermissionType>()
const subjects = ref<
  {
    title: string
    subtitle: string
    value: { id: string; type: ResourceType }
  }[]
>([])
const getPermissions = async () => {
  try {
    if (!metadata.value) return
    const permissions = await Network.permission.listPermissions(
      metadata.value.resourceType,
      metadata.value.resourceId
    )

    permissionsData.value = permissions
    console.log(permissionsData)
    const usersInPermissionsSet = new Set<number>()
    const groupsInPermissionsSet = new Set<number>()
    permissionsData.value.forEach((permission) => {
      originPermissionsDataMap.set(permission.id, permission.resource_relation)
      if (permission.subject_type === ResourceType.User) {
        usersInPermissionsSet.add(permission.subject_id)
        return
      }
      if (permission.subject_type === ResourceType.Group) {
        groupsInPermissionsSet.add(permission.subject_id)
        return
      }
    })
    const usersFiltered = listUsers.value
      .filter((user) => !usersInPermissionsSet.has(+user.value))
      .map((user) => ({
        ...user,
        icon: mdiAccount,
        value: { id: String(user.value), type: ResourceType.User }
      }))
    const groupsFiltered =
      groups.value?.data
        .filter((group) => !groupsInPermissionsSet.has(+group.id))
        .map((group) => ({
          title: group.name,
          subtitle: group.email,
          value: { id: String(group.id), type: ResourceType.Group },
          icon: mdiAccountMultiple
        })) ?? []

    subjects.value = [...groupsFiltered, ...usersFiltered]
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 403) {
      isUnauthorize.value = true
    }
  }
}

onUpdated(async () => {
  if (!metadata.value) return
  try {
    isLoading.value = true
    await Promise.all([userStore.getAllUsers(), groupStore.getAllGroups()])
    await getPermissions()
  } finally {
    isLoading.value = false
  }
})
/* FORM TO ADD PERMISSION USER */
const permission = ref<PermissionType>(PermissionType.Admin)
const permissionNewUsers = ref([
  {
    label: 'Leitor',
    value: PermissionType.View
  },
  {
    label: 'Editor',
    value: PermissionType.Admin
  }
])
const subjectsToShare = ref<{ id: string; type: ResourceType }[]>([])
const isNotifyShare = ref<boolean>(true)
const isLoading = ref<boolean>(false)
const messageNotify = ref<string>('')

const handleAddPermissions = async (resourceId: number, resourceType: ResourceType) => {
  const payload: UpsertPermissionsRequest = {
    resource_id: +resourceId,
    message: messageNotify.value,
    notify_share: isNotifyShare.value,
    subjects: subjectsToShare.value.map((subject) => {
      return {
        resource_relation: permission.value,
        subject_id: Number(subject.id),
        subject_type: subject.type
      }
    })
  }

  await Network.permission.addPermissions(resourceId, resourceType, payload)
}
/* ****** PERMISSIONS ******* */

const handleUpdatePermissions = async (resourceId: number, resourceType: ResourceType) => {
  const payload: UpsertPermissionsRequest = {
    resource_id: resourceId,
    subjects: permissionsData.value.flatMap((permission) => {
      // if not modify, return empty array
      if (originPermissionsDataMap.get(permission.id) === permission.resource_relation) return []

      const isDelete = permission.resource_relation === PermissionType.Remove
      return [
        {
          id: permission.id,
          resource_relation: isDelete ? PermissionType.View : permission.resource_relation,
          subject_id: permission.subject_id,
          subject_type: permission.subject_type,
          isDelete
        }
      ]
    })
  }

  await Network.permission.upsertPermissions(resourceId, resourceType, payload)
}

const handleSubmit = async () => {
  if (!metadata.value) return
  try {
    isLoading.value = true

    if (isShareContext.value) {
      await handleAddPermissions(metadata.value.resourceId, metadata.value.resourceType)
      toast.success('Permissões adicionadas com sucesso')
    } else {
      await handleUpdatePermissions(metadata.value.resourceId, metadata.value.resourceType)
      toast.success('Permissões atualizadas com sucesso')
    }

    handleToggleModal()
  } catch (err) {
    toast.error(DEFAULT_MESSAGES.error)
  } finally {
    isLoading.value = false
  }
}

const permissionsForSelect = ref([
  {
    label: 'Proprietário',
    value: PermissionType.Owner,
    disabled: true,
    active: false
  },
  {
    label: 'Leitor',
    value: PermissionType.View
  },
  {
    label: 'Editor',
    value: PermissionType.Admin
  },
  {
    label: 'Remover Acesso',
    value: PermissionType.Remove
  }
])

const metadata = computed(() => {
  return modalStore.metadata
})

const isShareContext = computed(() => {
  return subjectsToShare.value.length > 0
})

function clearFields() {
  subjectsToShare.value = []
  permissionsData.value = []
  messageNotify.value = ''
  isUnauthorize.value = false
}

const getInitalsName = (name: string) => {
  return name.substring(0, 2).toUpperCase()
}
</script>
<style scoped>
.fade-enter-active {
  transition: opacity 0.25s;
}
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.25s ease-out;
}

.slide-down-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-down-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.message-to-share :deep(.v-field__input) {
  height: 220px;
}
</style>
