<template>
  <CreateSchedule @openCreateHospitalModal="openCreateHospitalModal" />
  <CreatePosition @openCreateHospitalModal="openCreateHospitalModal" />
  <ListSchedules
    @removeSchedule="handleRemove"
    @updateSchedule="handleUpdate"
    @updatePosition="handleUpdatePosition"
    @removePosition="handleRemovePosition"
    @showPositions="handleToShowPositions"
  />
  <CreateHospitalModal
    type="create"
    :modalStateProp="showCreateHospitalModal"
    @close="showCreateHospitalModal = false"
  />
  <RemoveScheduleModal :schedule-id="scheduleIdToDelete" />
  <UpdateScheduleModal v-if="modals[EModals.UPDATE_SCHEDULE]" :schedule-id="scheduleToUpdate" />

  <PositionsModal :schedule-id="scheduleIdToShowPositions" />

  <RemovePositionModal :schedule-id="scheduleIdToDelete" :position-id="positionIdToDelete" />
  <UpdatePositionModal
    v-if="modals[EModals.UPDATE_POSITION]"
    :schedule-id="scheduleIdToShowPositions"
    :position-id="positionIdToUpdate"
  />
</template>

<script lang="ts">
import { onMounted, ref } from 'vue'
import CreateSchedule from './CreateSchedule.vue'
import CreatePosition from './CreatePosition.vue'
import ListSchedules from './ListSchedules.vue'
import CreateHospitalModal from '../../../modules/company/Units/modals/health.unit.modal.vue'
import RemoveScheduleModal from './modals/removeSchedule.vue'
import PositionsModal from './modals/positions.vue'
import UpdateScheduleModal from './modals/updateSchedule.vue'
import UpdatePositionModal from '@/components/ManageScale/Schedule/modals/updatePosition.vue'
import RemovePositionModal from '@/components/ManageScale/Schedule/modals/removePosition.vue'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { storeToRefs } from 'pinia'

export default {
  components: {
    CreateSchedule,
    CreatePosition,
    ListSchedules,
    RemoveScheduleModal,
    PositionsModal,
    UpdateScheduleModal,
    CreateHospitalModal,
    UpdatePositionModal,
    RemovePositionModal
  },
  setup() {
    const { modals } = storeToRefs(useModalStore())
    const healthUnitStore = useHealthUnitStore()
    const scheduleStore = useScheduleStore()
    onMounted(async () => {
      await Promise.allSettled([
        healthUnitStore.getHealthUnitOptions(true),
        scheduleStore.getScheduleOptions(true),
        scheduleStore.getSchedulesForPage()
      ])
    })

    const showCreateHospitalModal = ref(false)
    const scheduleIdToDelete = ref<number>(0)
    const scheduleIdToShowPositions = ref<number>(0)
    const scheduleToUpdate = ref<number>(0)

    const positionIdToDelete = ref<number>(0)
    const positionIdToUpdate = ref<number>(0)

    const openCreateHospitalModal = () => {
      showCreateHospitalModal.value = true
    }

    const handleRemove = (id: number) => {
      scheduleIdToDelete.value = id
    }

    const handleToShowPositions = (id: number) => {
      scheduleIdToShowPositions.value = id
    }

    const handleUpdate = (id: number) => {
      scheduleToUpdate.value = id
    }

    const handleRemovePosition = (id: number) => {
      positionIdToDelete.value = id
    }

    const handleUpdatePosition = ({
      scheduleId,
      positionId
    }: {
      scheduleId: number
      positionId: number
    }) => {
      scheduleIdToShowPositions.value = scheduleId
      positionIdToUpdate.value = positionId
    }

    return {
      handleRemove,
      handleUpdate,
      openCreateHospitalModal,
      scheduleIdToDelete,
      scheduleIdToShowPositions,
      scheduleToUpdate,
      handleToShowPositions,
      showCreateHospitalModal,
      modals,
      EModals,
      handleRemovePosition,
      handleUpdatePosition,
      UpdatePositionModal,
      RemovePositionModal,
      positionIdToDelete,
      positionIdToUpdate
    }
  }
}
</script>

<style></style>
