<template>
  <div class="hidden md:flex gap-2 flex-wrap">
    <filter-btn v-if="company" :text="`Empresa: ${company}`" :shouldRemove="false" />
    <template v-if="healthUnits?.length">
      <filter-btn
        v-for="healthUnit of healthUnits"
        :key="healthUnit"
        :text="`Unidade: ${healthUnit}`"
        :shouldRemove="false"
      />
    </template>
    <template v-if="schedules?.length">
      <filter-btn
        v-for="schedule of schedules"
        :key="schedule"
        :text="`Escala: ${schedule}`"
        :shouldRemove="false"
      />
    </template>
    <template v-if="positions?.length">
      <filter-btn
        v-for="position of positions"
        :key="position"
        :text="`Posição: ${position}`"
        :shouldRemove="false"
      />
    </template>
    <template v-if="doctors?.length">
      <filter-btn
        v-for="doctor of doctors"
        :key="doctor"
        :text="`Dr(a): ${doctor}`"
        :shouldRemove="false"
      />
    </template>
    <filter-btn
      v-if="filters.valueMin"
      :text="`Valor Min.: R$ ${filters.valueMin}`"
      :shouldRemove="false"
    />
    <filter-btn
      v-if="filters.valueMax"
      :text="`Valor Max.: R$ ${filters.valueMax}`"
      :shouldRemove="false"
    />
    <filter-btn
      v-if="filters.valueMax"
      :text="`Valor Max.: R$ ${filters.valueMax}`"
      :shouldRemove="false"
    />
    <filter-btn
      v-if="filters.paymentValueMin"
      :text="`Valor Aprov Min.: R$ ${filters.paymentValueMin}`"
      :shouldRemove="false"
    />
    <filter-btn
      v-if="filters.paymentValueMax"
      :text="`Valor Aprov Max.: R$ ${filters.paymentValueMax}`"
      :shouldRemove="false"
    />
    <filter-btn
      v-if="filters.startDate"
      :text="`Data Ini.: ${formatDate(filters.startDate)}`"
      :shouldRemove="false"
    />
    <filter-btn
      v-if="filters.endDate"
      :text="`Data Fin.: ${formatDate(filters.endDate)}`"
      :shouldRemove="false"
    />
    <filter-btn
      v-if="filters.days?.length"
      :text="`Dias: ${filters.days.map((d) => daysWeekObj[d]).join(', ')}`"
      :shouldRemove="false"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import FilterBtn from '@/components/Dashboard/Header/components/FilterBtn.vue'
import { useFilterStationsStore } from '@/components/Filters/useFilterStation'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { computed } from 'vue'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { usePositionStore } from '@/pinia-store/usePositionStore'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'
import { format } from 'date-fns'
import { daysWeekObj } from '@/utils/date'

const useFilterStations = useFilterStationsStore()
const { filters } = storeToRefs(useFilterStations)

const { listCompanies } = storeToRefs(useCompanyStore())
const { listHealthUnits } = storeToRefs(useHealthUnitStore())
const { listSchedules } = storeToRefs(useScheduleStore())
const { listPositions } = storeToRefs(usePositionStore())
const { listDoctorsCompany } = storeToRefs(useDoctorStore())

const company = computed(() => {
  return listCompanies.value.find((c) => +c.value === Number(filters.value.company))?.title
})

const healthUnits = computed(() => {
  const healthUnits = listHealthUnits.value.filter((h) =>
    filters.value.healthUnits?.map((hu) => Number(hu))?.includes(+h.value)
  )
  return healthUnits.map((h) => h.title)
})

const schedules = computed(() => {
  const schedules = listSchedules.value.filter((h) =>
    filters.value.schedules?.map((hu) => Number(hu))?.includes(+h.value)
  )
  return schedules.map((h) => h.title)
})

const positions = computed(() => {
  const positions = listPositions.value.filter((h) =>
    filters.value.positions?.map((hu) => Number(hu))?.includes(+h.id)
  )
  return positions.map((h) => h.name)
})

const doctors = computed(() => {
  const doctors = listDoctorsCompany.value.filter((h) =>
    filters.value.doctors?.map((hu) => Number(hu))?.includes(+h.value)
  )
  return doctors.map((h) => h.title)
})

function formatDate(date: string | Date) {
  if (typeof date !== 'string') {
    return format(date, 'dd/MM/yyyy')
  }
  return date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')
}
</script>
