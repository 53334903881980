<template>
  <div class="flex flex-col w-full bg-gray-50 rounded-lg p-2">
    <div class="cursor-pointer my-2 flex items-center gap-1 justify-between">
      <span class="font-bold">Horários</span>
      <edit-pencil-icon v-if="!toggleEdit" @click="toggleEdit = !toggleEdit" color="#2F7F91" />
      <div v-if="toggleEdit" class="flex gap-2 text-xs text-gray-500 font-bold">
        <span @click="saveEditMode">
          <v-icon :icon="mdiCheckCircleOutline" color="#22bb33" class="cursor-pointer" />
          Salvar Rascunho
        </span>
        <span @click="closeEditMode">
          <v-icon :icon="mdiCloseCircleOutline" color="#bb2124" class="cursor-pointer" />
          Descartar Rascunho
        </span>
      </div>
    </div>

    <div class="flex w-full">
      <div class="w-[10%]"></div>

      <div class="w-[20%] text-start">
        <h1>Checkin</h1>
      </div>

      <div class="w-[25%] text-start">
        <h1>Checkout</h1>
      </div>

      <div class="w-[10%] text-start">
        <h1>Origem</h1>
      </div>

      <div class="w-[10%] text-start">
        <h1>Evidência</h1>
      </div>

      <div class="w-[5%]"></div>
    </div>

    <v-radio-group v-model="isCheckMain">
      <div v-for="(info, i) in draftInformations" :key="i" class="flex w-full items-center mt-3">
        <div class="w-[10%] h-[44px] flex items-center text-xs font-bold text-gray-500">
          <v-radio
            :disabled="!toggleEdit"
            label=""
            name="checks"
            id="checks"
            color="primary"
            :value="i"
          />
        </div>
        <div class="w-[20%] h-[40px] flex items-start">
          <div class="h-full mr-1">
            <a
              v-if="info.origin === 'DOCTOR'"
              class="h-full flex justify-center items-center"
              :href="`https://www.google.com/maps/dir/${info.checkinLatitude},${info.checkinLongitude}/${healthUnitLatitude},${healthUnitLongitude}`"
              target="_blank"
            >
              <v-icon class="hover:cursor-pointer" :icon="mdiMapMarkerRadius" color="primary" />
            </a>
            <div v-else class="w-[24px]"></div>
          </div>
          <GenericInput
            :disabled="!toggleEdit || info.origin === 'DOCTOR'"
            v-model="info.checkin"
            name="checkin"
            density="compact"
            :label="info.labelCheckin || ''"
            v-maska="'##:##'"
            width="300px"
            variant="outlined"
          />
        </div>
        <div class="w-[25%] h-[40px] flex items-start">
          <div class="h-full mr-1">
            <a
              v-if="info.origin === 'DOCTOR'"
              class="h-full flex justify-center items-center"
              :href="`https://www.google.com/maps/dir/${info.checkoutLatitude},${info.checkoutLongitude}/${healthUnitLatitude},${healthUnitLongitude}`"
              target="_blank"
            >
              <v-icon class="hover:cursor-pointer" :icon="mdiMapMarkerRadius" color="primary" />
            </a>
            <div v-else class="w-[24px]"></div>
          </div>
          <GenericInput
            :disabled="!toggleEdit || info.origin === 'DOCTOR'"
            v-model="info.checkout"
            name="checkout"
            density="compact"
            :label="info.labelCheckout || ''"
            v-maska="'##:##'"
            width="100%"
            @click:prepend="
              (e) => {
                console.log('prepend', e)
              }
            "
            variant="outlined"
          />
        </div>
        <div class="w-[10%] h-[44px] flex items-center text-xs font-bold text-gray-500">
          {{ info.origin === 'DOCTOR' ? 'Doutor' : 'Web' }}
        </div>

        <!-- Evidence Section -->
        <template v-if="info.origin !== 'DOCTOR'">
          <div class="w-[30%] h-auto flex flex-col items-start">
            <!-- Edit Mode -->
            <div v-if="toggleEdit">
              <div
                v-if="
                  (!draftInformations[i].evidence || draftInformations[i].evidence.length === 0) &&
                  (!draftInformations[i].checkEvidences ||
                    draftInformations[i].checkEvidences.length === 0)
                "
                class="w-full"
              >
                <v-btn color="primary" depressed class="text-xs w-[200px]" @click="openModal(i)">
                  Selecionar Arquivos
                </v-btn>
              </div>
              <div v-else class="w-full">
                <v-btn
                  color="primary"
                  depressed
                  class="text-xs w-[200px]"
                  @click="showFiles(selectedFileNames[i], i)"
                >
                  {{ selectedFileNames[i].length }} Arquivos
                </v-btn>
              </div>
            </div>
            <!-- View Mode -->
            <div v-else>
              <div
                v-if="
                  (draftInformations[i].checkEvidences &&
                    draftInformations[i].checkEvidences.length > 0) ||
                  (draftInformations[i].evidence && draftInformations[i].evidence.length > 0)
                "
                class="w-full"
              >
                <div class="w-full">
                  <v-btn
                    color="primary"
                    depressed
                    class="text-xs w-[200px]"
                    @click="showFiles(selectedFileNames[i], i)"
                  >
                    {{ selectedFileNames[i].length }} Arquivos
                  </v-btn>
                </div>
              </div>
              <div v-else class="w-full">
                <span class="block text-xs text-gray-500 text-left">--</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="w-[30%] flex flex-col">
            <span class="block text-xs text-gray-500 text-left">--</span>
          </div>
        </template>

        <button
          :disabled="!toggleEdit || info.origin === 'DOCTOR'"
          @click="removeCheck(i)"
          class="w-[5%] h-[44px] flex items-center"
        >
          <v-icon
            :icon="mdiDeleteOutline"
            :color="info.origin === 'DOCTOR' ? '#D3D3D3' : colorHex"
          />
        </button>
      </div>
    </v-radio-group>

    <button :disabled="!toggleEdit" @click="addNewCheck" class="my-2 flex items-center gap-1">
      <rounded-plus :color="colorHex" />
      <span class="font-bold text-sm" :class="colorText">Incluir mais horários</span>
    </button>
  </div>

  <v-dialog v-model="showModal" max-width="700">
    <v-card>
      <v-card-title class="text-h6">Selecione os arquivos</v-card-title>
      <v-card-text>
        <v-file-input
          v-model="files"
          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
          counter
          multiple
          label="Arraste e solte os arquivos aqui ou clique para escolher"
          variant="outlined"
          :rules="[fileSizeRule]"
          :show-details="{ error: true }"
          @change="(e) => onEvidenceChange(e, currentCheckIndex)"
          show-size
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="submitModalEvidence" :disabled="!filesValid">
          Enviar Arquivos
        </v-btn>
        <v-btn color="primary" text @click="closeModal">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilesModal" max-width="700">
    <v-card>
      <v-card-title class="text-h6">Arquivos Selecionados</v-card-title>
      <v-card-text>
        <div v-if="modalFiles && modalFiles.length > 0">
          <v-row class="font-bold items-center">
            <v-col cols="8" class="text-start">Nome do Arquivo</v-col>
            <v-col cols="4" class="flex justify-center items-center">Download</v-col>
          </v-row>
          <v-row v-for="(file, idx) in modalFiles" :key="idx" class="items-start">
            <v-col cols="8" class="text-start">{{ file.name }}</v-col>
            <v-col v-if="file.path" cols="4" class="flex justify-center items-start">
              <v-btn icon color="primary" @click.stop="downloadFile(file.path)">
                <DownloadIcon
                  width="24"
                  height="24"
                  aria-label="Download do Arquivo"
                  title="Download"
                />
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <span>Nenhum arquivo selecionado.</span>
        </div>
        <v-divider class="my-4" />
        <v-file-input
          v-if="toggleEdit"
          v-model="files"
          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
          counter
          multiple
          label="Arraste e solte os arquivos aqui ou clique para escolher"
          variant="outlined"
          :rules="[fileSizeRule]"
          :show-details="{ error: true }"
          @change="(e) => onEvidenceChange(e, currentCheckIndex)"
          show-size
        ></v-file-input>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="toggleEdit"
          color="primary"
          text
          @click="submitModalEvidence"
          :disabled="!filesValid || !(files.length > 0)"
        >
          Enviar Arquivos
        </v-btn>
        <v-btn color="primary" text @click="closeFilesModal">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, defineProps, defineEmits } from 'vue'
import { format } from 'date-fns'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'
import RoundedPlus from '@/assets/icons/rounded-plus.vue'
import EditPencilIcon from '@/assets/icons/edit-pencil.vue'
import {
  mdiDeleteOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiMapMarkerRadius
} from '@mdi/js'
import { CheckOrigin, HistoryDate, CheckEvidence } from '@/Network/Types/Requests/StationRequests'
import DownloadIcon from '@/assets/icons/download-icon.vue'

const props = defineProps<{
  infos: HistoryDate[]
  healthUnitLatitude?: string
  healthUnitLongitude?: string
}>()

const emit = defineEmits(['saveChecks'])

const draftInformations = ref<HistoryDate[]>([])
const isCheckMain = ref<number | undefined>(undefined)
const toggleEdit = ref<boolean>(false)
const informations = ref<HistoryDate[]>([])
const selectedFileNames = ref<CheckEvidence[][]>([])

onMounted(() => {
  informations.value = props.infos.map((v, i) => {
    if (v.isMain) isCheckMain.value = i
    return {
      id: v.id,
      checkin: format(new Date(v.checkin), 'HH:mm'),
      labelCheckin: v.checkin ? format(new Date(v.checkin), 'dd/MM/yyyy') : '',
      checkinLatitude: v.checkinLatitude,
      checkoutLongitude: v.checkoutLongitude,
      checkout: v.checkout ? format(new Date(v.checkout), 'HH:mm') : undefined,
      labelCheckout: v.checkout ? format(new Date(v.checkout), 'dd/MM/yyyy') : '',
      checkoutLatitude: v.checkoutLatitude,
      checkinLongitude: v.checkinLongitude,
      origin: v.origin ?? CheckOrigin.DOCTOR,
      isMain: v.isMain,
      checkEvidences: v.checkEvidences && v.checkEvidences.length > 0 ? v.checkEvidences : [],
      evidence: v.evidence || []
    }
  })

  draftInformations.value = [...informations.value]

  selectedFileNames.value = draftInformations.value.map((item) => {
    if (item.checkEvidences && item.checkEvidences.length > 0) {
      return item.checkEvidences
    } else if (item.evidence && item.evidence.length > 0) {
      return item.evidence.map((file: File) => ({
        name: file.name,
        path: ''
      }))
    }
    return []
  })
})

const colorHex = computed(() => (toggleEdit.value ? '#2F7F91' : '#D3D3D3'))
const colorText = computed(() => (toggleEdit.value ? 'text-primary' : 'text-gray-300'))

function addNewCheck() {
  draftInformations.value.push({
    checkin: '',
    checkout: '',
    origin: CheckOrigin.WEB,
    isMain: false,
    evidence: []
  })
}

function removeCheck(pos: number) {
  draftInformations.value = draftInformations.value.filter((_v, i) => i !== pos)
}

function closeEditMode() {
  toggleEdit.value = false
  draftInformations.value = informations.value
}

function saveEditMode() {
  let validForm = true
  draftInformations.value = draftInformations.value.map((c, i) => {
    if (!validateTime(c.checkin)) validForm = false
    return {
      ...c,
      isMain: i === isCheckMain.value
    }
  })

  if (!validForm) return
  toggleEdit.value = false
  emit('saveChecks', draftInformations.value)
}

function validateTime(hours: string) {
  const [hour, minutes] = hours.split(':')
  const isValidHours = Number(hour) >= 0 && Number(hour) <= 23
  const isValidMinutes = Number(minutes) >= 0 && Number(minutes) <= 59
  return isValidHours && isValidMinutes
}

watch(
  draftInformations,
  (newChecks) => {
    if (!(selectedFileNames.value && selectedFileNames.value.length > 0)) {
      selectedFileNames.value = newChecks.map((check) => {
        if (check.evidence && check.evidence.length > 0) {
          return check.evidence.map((e) => ({
            name: e.name,
            path: ''
          }))
        }
        if (check.checkEvidences && check.checkEvidences.length > 0) {
          return check.checkEvidences.map((e) => ({
            name: e.name,
            path: e.path
          }))
        }
        return []
      })
    }
  },
  { deep: true }
)

const showModal = ref(false)
const files = ref<File[]>([])
const currentCheckIndex = ref<number>(0)
const showFilesModal = ref(false)
const modalFiles = ref<CheckEvidence[]>([])
const modalSelectedFiles = ref<File[]>([])

watch(
  files,
  (newFiles) => {
    if (!newFiles || newFiles.length === 0) {
      modalSelectedFiles.value = []
    }
  },
  { immediate: true, deep: true }
)

function showFiles(fileNames: CheckEvidence[], index: number) {
  modalFiles.value = fileNames
  currentCheckIndex.value = index
  showFilesModal.value = true
}

function closeFilesModal() {
  showFilesModal.value = false
}

function openModal(index: number) {
  currentCheckIndex.value = index
  showModal.value = true
}

function closeModal() {
  showModal.value = false
}

function submitModalEvidence() {
  const index = currentCheckIndex.value
  if (modalSelectedFiles.value && modalSelectedFiles.value.length > 0) {
    draftInformations.value[index].evidence = [
      ...(draftInformations.value[index].evidence || []),
      ...modalSelectedFiles.value
    ]
    selectedFileNames.value[index] = [
      ...(selectedFileNames.value[index] || []),
      ...modalSelectedFiles.value.map((file: File) => ({ name: file.name, path: '' }))
    ]
    modalSelectedFiles.value = []
    files.value = []
  }
  closeModal()
  closeFilesModal()
}

function onEvidenceChange(event: Event, index: number) {
  const target = event.target as HTMLInputElement
  const selectedFiles = target.files
  console.log(index)
  if (selectedFiles && selectedFiles.length > 0) {
    modalSelectedFiles.value = Array.from(selectedFiles)
  }
}

function downloadFile(url: string) {
  window.open(url, '_blank')
}

const maxFileSize = 5 * 1024 * 1024

function fileSizeRule(files: File[] | null): true | string {
  if (!files || files.length === 0) return true
  for (const file of files) {
    if (file.size > maxFileSize) {
      return `O tamanho máximo permitido é ${maxFileSize / (1024 * 1024)} MB.`
    }
  }
  return true
}

const filesValid = computed(() => {
  const result = fileSizeRule(files.value)
  return result === true
})
</script>

<style scoped>
:deep(.v-field__input) {
  width: 6.4em;
  text-align: center;
}
:deep(.v-input--disabled .v-input__prepend) {
  opacity: 1;
}

.custom-cursor:hover {
  cursor: crosshair;
}
</style>
