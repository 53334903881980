<template>
  <v-row dense>
    <v-col
      cols="12"
      class="flex items-center gap-2 cursor-pointer"
      @click="showForm = !showForm"
      :class="showForm ? 'bg-gray-100 rounded-t-lg px-4 py-2' : ''"
    >
      <h2 class="text-left font-bold text-[18px]">Criar Posição</h2>
      <arrow-icon :class="showForm ? 'rotate-180' : ''" color="#000" />
    </v-col>

    <v-row
      v-if="showForm"
      dense
      class="items-center bg-gray-100 rounded-lg p-2"
      :class="showForm ? 'rounded-t-none' : ''"
    >
      <v-col class="md:mt-0" cols="12" md="3">
        <v-text-field
          v-model="positionName"
          label="Nome*"
          class="w-full"
          color="primary20"
          variant="outlined"
          hide-details
        />
      </v-col>
      <v-col class="md:mt-0 mt-4" cols="12" md="3">
        <v-autocomplete
          @update:model-value="healthUnitChanged"
          v-model="healthUnitSelected"
          class="w-full"
          color="primary20"
          label="Buscar Unidade *"
          theme="light"
          :items="healthUnitOptions"
          variant="outlined"
          hide-details
          no-data-text="Nenhuma unidade encontrada"
          :loading="isLoadingSelectFetch"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item?.raw?.title"
              :subtitle="item?.raw?.subtitle"
            ></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="md:mt-0 mt-4" cols="12" md="3">
        <v-autocomplete
          v-model="scheduleSelected"
          class="w-full"
          color="primary20"
          label="Buscar Escala *"
          theme="light"
          :items="scheduleOptions"
          variant="outlined"
          hide-details
          :disabled="!healthUnitSelected || scheduleStore.isLoading"
          no-data-text="Nenhuma escala encontrada"
          :loading="isLoadingSelectFetch"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item?.raw?.title"
              :subtitle="item?.raw?.subtitle"
            ></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" class="flex justify-end">
        <span class="w-full flex justify-end gap-8">
          <filled-button
            @click="createPosition"
            :disabled="!positionName || !scheduleSelected || isLoading"
            width="143px"
            text="Criar Posição"
            :loading="isLoading"
          />
        </span>
      </v-col>
    </v-row>
  </v-row>
</template>

<script setup lang="ts">
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { usePositionStore } from '@/pinia-store/usePositionStore'
import { computed } from 'vue'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import ArrowIcon from '@/assets/icons/down.vue'
import { CreatePosition } from '@/Network/Types/Requests/PositionRequests'
import { useToast } from 'vue-toastification'

const scheduleSelected = ref<string | undefined>(undefined)
const healthUnitSelected = ref<string | undefined>(undefined)

const isLoading = ref(false)
const showForm = ref(true)
const { schedulesAdminAccess, isLoadingSelectFetch } = storeToRefs(useScheduleStore())

const positionName = ref<string>('')

const scheduleStore = useScheduleStore()
const positionStore = usePositionStore()
const healthUnitOptions = computed(() => {
  const healthUnitSet = new Set<string>()
  schedulesAdminAccess.value.forEach((item) => {
    if (!item.subtitle) return
    healthUnitSet.add(item.subtitle)
  })

  return Array.from(healthUnitSet).map((unitName) => ({ title: unitName, value: unitName }))
})

const scheduleOptions = computed(() => {
  return schedulesAdminAccess.value.filter((item) => item.subtitle === healthUnitSelected.value)
})

const healthUnitChanged = () => {
  scheduleSelected.value = undefined
}

const clearInputs = () => {
  isLoading.value = false
  positionName.value = ''
  scheduleSelected.value = undefined
  healthUnitSelected.value = undefined
}

const toast = useToast()

const createPosition = async () => {
  try {
    isLoading.value = true
    const data: CreatePosition = {
      name: positionName.value,
      scheduleId: Number(scheduleSelected.value)
    }
    await positionStore.create(data)

    const schedule = await scheduleStore.getScheduleById(Number(scheduleSelected.value))

    toast.success(`Posição: ${positionName.value} criada com sucesso no plantão: ${schedule.name}`)
    clearInputs()
  } catch (error) {
    console.error(error)
    toast.error('Erro ao criar posição, por favor tente novamente!')
  } finally {
    isLoading.value = false
  }
}
</script>
