<template>
  <div class="flex flex-col gap-2 w-full">
    <v-row dense>
      <v-col cols="12">
        <h2 class="text-left font-bold text-[18px]">Lista de escalas</h2>
      </v-col>
    </v-row>
    <v-progress-linear v-if="isLoadingSchedulesForPage" indeterminate color="primary" />
    <div v-for="company in schedulesForPage" :key="company.id" class="flex w-full flex-col">
      <div class="flex w-full">
        <span
          @click="toggleCompanies(company.id)"
          class="header-title"
          :class="showCompanies[company.id] ? 'rounded-b-none bg-gray-200' : ''"
        >
          <div class="flex flex-col justify-start text-left font-normal">
            <span class="text-gray-500 text-xs">Empresa:</span>
            <span class="text-primary font-bold">{{ company.name.toLocaleLowerCase() }}</span>
          </div>
          <div class="flex gap-1">
            <v-icon
              v-if="isEnabledPermissions() && company.isAdmin"
              class="share-icon hover:cursor-pointer"
              color="#52526F"
              :icon="mdiShareVariantOutline"
              @click.stop="openShareModal('Empresa', ResourceType.Company, company.id)"
            />
            <arrow-icon :class="showCompanies[company.id] ? 'rotate-180' : ''" color="#000" />
          </div>
        </span>
      </div>
      <template v-if="showCompanies[company.id]">
        <div
          v-for="healthUnit of company.units"
          :key="healthUnit.id"
          class="card"
          @click="toggleHealthUnits(healthUnit.id)"
          :class="showHealthUnits[healthUnit.id] ? 'rounded-b-none bg-gray-100' : ''"
        >
          <div class="flex flex-col text-left">
            <span class="text-gray-500 text-xs">Unidade:</span>
            <span class="capitalize">{{ healthUnit.name.toLocaleLowerCase() }}</span>
          </div>
          <div class="flex gap-1">
            <v-icon
              v-if="isEnabledPermissions() && healthUnit.isAdmin"
              class="share-icon hover:cursor-pointer"
              color="#52526F"
              :icon="mdiShareVariantOutline"
              @click.stop="openShareModal('Unidade', ResourceType.HealthUnit, healthUnit.id)"
            />
            <arrow-icon :class="showHealthUnits[healthUnit.id] ? 'rotate-180' : ''" color="#000" />
          </div>

          <template v-if="showHealthUnits[healthUnit.id]">
            <div
              v-for="schedule of healthUnit.schedules"
              :key="schedule.id"
              @click.stop="togglePositions(schedule.id)"
              class="card2 bg-gray-50"
            >
              <div class="flex flex-col text-left">
                <span class="text-gray-500 text-xs">Escala:</span>
                <span class="capitalize">{{ schedule.name.toLocaleLowerCase() }}</span>
                <div class="flex flex-wrap md:gap-2 items-center text-gray-500 text-sm">
                  <span>{{ getTimezoneDetails(schedule.timezone)?.title }}</span>
                  <span>({{ getTimezoneDetails(schedule.timezone)?.subtitle }})</span>
                </div>
              </div>
              <span class="flex gap-1 justify-center items-center">
                <v-icon
                  v-if="isEnabledPermissions() && schedule.isAdmin"
                  class="share-icon hover:cursor-pointer"
                  color="#52526F"
                  :icon="mdiShareVariantOutline"
                  @click.stop="openShareModal('Escala', ResourceType.Schedule, schedule.id)"
                />
                <edit-icon
                  v-if="schedule.isAdmin"
                  @click.stop="handleUpdate(schedule.id)"
                  class="cursor-pointer"
                />
                <delete-icon
                  v-if="schedule.isAdmin"
                  @click.stop="handleRemove(schedule.id)"
                  class="cursor-pointer"
                />
                <div class="flex gap-1" v-if="schedule.isAdmin && schedule.positions.length > 0">
                  <arrow-icon
                    :class="showPositions[schedule.id] ? 'rotate-180' : ''"
                    color="#52526F"
                    @click.stop="togglePositions(schedule.id)"
                    class="cursor-pointer"
                  />
                </div>
              </span>

              <template v-if="showPositions[schedule.id]">
                <div
                  v-for="position of schedule.positions"
                  :key="position.id"
                  class="card2 bg-gray-50"
                >
                  <div class="flex flex-col text-left">
                    <span class="text-gray-500 text-xs">Posição:</span>
                    <span class="capitalize">{{ position.name.toLocaleLowerCase() }}</span>
                  </div>

                  <span class="flex gap-1 justify-center items-center">
                    <!-- <v-icon
                      v-if="isEnabledPermissions() && schedule.isAdmin"
                      class="share-icon hover:cursor-pointer"
                      color="#52526F"
                      :icon="mdiShareVariantOutline"
                      @click.stop="openShareModal('Escala', ResourceType.Schedule, schedule.id)"
                    /> -->
                    <edit-icon
                      v-if="schedule.isAdmin"
                      @click.stop="handleUpdatePosition(position.id, schedule.id)"
                      class="cursor-pointer"
                    />
                    <delete-icon
                      v-if="schedule.isAdmin"
                      @click.stop="handleRemovePosition(position.id)"
                      class="cursor-pointer"
                    />
                  </span>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </div>

    <share-modal />
  </div>
</template>

<script setup lang="ts">
import ArrowIcon from '@/assets/icons/down.vue'
import EditIcon from '@/assets/icons/edit.vue'
import DeleteIcon from '@/assets/icons/delete.vue'
import { mdiShareVariantOutline } from '@mdi/js'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { timezones } from '@/hooks/useCommon'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import ShareModal from '@/components/designSystem/modals/share.modal.vue'
import { ResourceType } from '@/Network/Types/Responses/PermissionResponses'
import { useFeatureFlags } from '@/hooks/useFeatureFlags'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'

const emit = defineEmits([
  'removeSchedule',
  'removeSchedule',
  'removePosition',
  'updateSchedule',
  'updatePosition',
  'showPositions'
])

const modalStore = useModalStore()
const showCompanies = ref<Record<string, boolean>>({})
const showHealthUnits = ref<Record<string, boolean>>({})
const showPositions = ref<Record<number, boolean>>({})
const { schedulesForPage, isLoadingSchedulesForPage } = storeToRefs(useScheduleStore())

const toggleCompanies = (id: number) => {
  showCompanies.value = { ...showCompanies.value, [id]: showCompanies.value[id] ? false : true }
}

const toggleHealthUnits = (id: number) => {
  showHealthUnits.value = {
    ...showHealthUnits.value,
    [id]: showHealthUnits.value[id] ? false : true
  }
}

const togglePositions = (scheduleId: number) => {
  showPositions.value = {
    ...showPositions.value,
    [scheduleId]: !showPositions.value[scheduleId]
  }
}

const getTimezoneDetails = (time: string) => {
  return timezones.find((t) => t.value === time)
}

const handleRemove = (id: number) => {
  emit('removeSchedule', id)
  modalStore.toggleModal(EModals.REMOVE_SCHEDULE)
}

const handleUpdate = (scheduleId: number) => {
  emit('updateSchedule', scheduleId)
  modalStore.toggleModal(EModals.UPDATE_SCHEDULE)
}

const handleUpdatePosition = (positionId: number, scheduleId: number) => {
  console.log('update')
  emit('updatePosition', { scheduleId: scheduleId, positionId: positionId })
  modalStore.toggleModal(EModals.UPDATE_POSITION)
}

const handleRemovePosition = (id: number) => {
  emit('removePosition', id)
  modalStore.toggleModal(EModals.REMOVE_POSITION)
}

const openShareModal = (
  resourceName: string,
  resourceType: ResourceType,
  resourceId: string | number
) => {
  modalStore.metadata = {
    resourceName: resourceName,
    resourceType: resourceType,
    resourceId: +resourceId
  }
  modalStore.toggleModal(EModals.SHARE)
}

const { flags } = useFeatureFlags()
const isEnabledPermissions = () => flags.enabledPermissions
</script>

<style scoped>
.share-icon :deep(svg) {
  width: 22px;
  height: 22px;
}

.header-title {
  @apply flex justify-between items-center gap-4 w-full px-[12px] py-[16px] rounded-lg bg-[#FAFAFA] text-black text-[16px] font-bold cursor-pointer capitalize;
}
.card,
.card2 {
  @apply w-full flex flex-wrap gap-4 items-center justify-between px-[12px] py-[10px] bg-[#FAFAFA] cursor-pointer capitalize;
}
.card2 {
  @apply p-2 cursor-default rounded-lg;
}
.card:hover {
  @apply bg-gray-200;
}
</style>
