<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" isFilterModal>
    <template #header>
      <p class="font-bold">Editar posição</p>
    </template>
    <v-progress-linear v-if="!data || isLoading" indeterminate color="primary" />
    <div v-else class="flex flex-col">
      <v-text-field
        v-model="data.positionName"
        label="Nome da Posição*"
        class="w-full"
        color="primary20"
        variant="outlined"
        :errorMessages="errors.positionName"
      />
    </div>
    <template #footer>
      <div class="flex justify-between gap-2 w-full">
        <outline-button width="119px" @click="handleToggleModal" text="Cancelar" />
        <filled-button
          :disabled="isLoading"
          :loading="isLoading"
          text="Editar"
          width="119px"
          @click="handleSubmit"
        />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useToast } from 'vue-toastification'
import Network from '@/Network'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'

const props = defineProps({
  positionId: {
    type: Number,
    required: true
  },
  scheduleId: {
    type: Number,
    required: true
  }
})

const modalName = EModals.UPDATE_POSITION
const { modals } = storeToRefs(useModalStore())
const scheduleStore = useScheduleStore()
const modalStore = useModalStore()
const toast = useToast()

const data = ref<{
  positionName: string
}>()
const errors = ref<{ positionName: string }>({
  positionName: ''
})

const isLoading = ref(false)

onMounted(async () => {
  console.log('MountedModal')
  if (!props.positionId) return
  clearErrs()

  try {
    isLoading.value = true
    const apiResponse = await Network.position.getById(props.positionId)
    data.value = {
      positionName: apiResponse.name
    }
  } finally {
    isLoading.value = false
  }
})

const hasError = () => {
  let errorFound = false
  if (!data.value?.positionName || data.value.positionName.trim().length === 0) {
    errors.value.positionName = 'Informe um nome para posição'
    errorFound = true
  }
  return errorFound
}

const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
  data.value = undefined
  clearErrs()
}

const handleSubmit = async () => {
  if (!data.value || hasError()) {
    toast.error('Dados inválidos ou não preenchidos')
    return
  }

  try {
    isLoading.value = true

    await Network.position.update(props.positionId, {
      name: data.value.positionName,
      scheduleId: props.scheduleId
    })
    await scheduleStore.getSchedulesForPage()
    modalStore.alertDescription = 'Posição editada com sucesso!'
    modalStore.toggleModal(modalName)
    modalStore.toggleShowAlert()
  } finally {
    isLoading.value = false
  }
}

const clearErrs = () => {
  errors.value.positionName = ''
}
</script>
