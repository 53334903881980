import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { ListPositionsResponse, PositionResponse } from '../Types/Responses/PositionResponses'
import { CreatePosition } from '../Types/Requests/PositionRequests'

export default class PositionService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/position'
  }

  async create(payload: CreatePosition): Promise<PositionResponse> {
    try {
      const { data } = await request.post<PositionResponse>(`${this.getCompletePath()}`, payload)
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async list(): Promise<ListPositionsResponse> {
    try {
      const { data } = await request.get<ListPositionsResponse>(`${this.getCompletePath()}`)
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async remove(positionId: number): Promise<void> {
    try {
      await request.delete(`${this.getCompletePath()}/${positionId}`)
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async update(positionId: number, payload: CreatePosition): Promise<PositionResponse> {
    const { data } = (await request.patch(`${this.getCompletePath()}/${positionId}`, payload)) as {
      data: PositionResponse
    }
    return data
  }

  async getByScheduleId(scheduleId: number): Promise<ListPositionsResponse> {
    try {
      const { data } = await request.get<ListPositionsResponse>(
        `${this.getCompletePath()}/schedule/${scheduleId}`
      )
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async getById(positionId: number): Promise<PositionResponse> {
    try {
      const { data } = await request.get<PositionResponse>(
        `${this.getCompletePath()}/${positionId}`
      )
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }
}
