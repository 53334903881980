import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateSchedule = _resolveComponent("CreateSchedule")!
  const _component_CreatePosition = _resolveComponent("CreatePosition")!
  const _component_ListSchedules = _resolveComponent("ListSchedules")!
  const _component_CreateHospitalModal = _resolveComponent("CreateHospitalModal")!
  const _component_RemoveScheduleModal = _resolveComponent("RemoveScheduleModal")!
  const _component_UpdateScheduleModal = _resolveComponent("UpdateScheduleModal")!
  const _component_PositionsModal = _resolveComponent("PositionsModal")!
  const _component_RemovePositionModal = _resolveComponent("RemovePositionModal")!
  const _component_UpdatePositionModal = _resolveComponent("UpdatePositionModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CreateSchedule, { onOpenCreateHospitalModal: $setup.openCreateHospitalModal }, null, 8, ["onOpenCreateHospitalModal"]),
    _createVNode(_component_CreatePosition, { onOpenCreateHospitalModal: $setup.openCreateHospitalModal }, null, 8, ["onOpenCreateHospitalModal"]),
    _createVNode(_component_ListSchedules, {
      onRemoveSchedule: $setup.handleRemove,
      onUpdateSchedule: $setup.handleUpdate,
      onUpdatePosition: $setup.handleUpdatePosition,
      onRemovePosition: $setup.handleRemovePosition,
      onShowPositions: $setup.handleToShowPositions
    }, null, 8, ["onRemoveSchedule", "onUpdateSchedule", "onUpdatePosition", "onRemovePosition", "onShowPositions"]),
    _createVNode(_component_CreateHospitalModal, {
      type: "create",
      modalStateProp: $setup.showCreateHospitalModal,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.showCreateHospitalModal = false))
    }, null, 8, ["modalStateProp"]),
    _createVNode(_component_RemoveScheduleModal, { "schedule-id": $setup.scheduleIdToDelete }, null, 8, ["schedule-id"]),
    ($setup.modals[$setup.EModals.UPDATE_SCHEDULE])
      ? (_openBlock(), _createBlock(_component_UpdateScheduleModal, {
          key: 0,
          "schedule-id": $setup.scheduleToUpdate
        }, null, 8, ["schedule-id"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PositionsModal, { "schedule-id": $setup.scheduleIdToShowPositions }, null, 8, ["schedule-id"]),
    _createVNode(_component_RemovePositionModal, {
      "schedule-id": $setup.scheduleIdToDelete,
      "position-id": $setup.positionIdToDelete
    }, null, 8, ["schedule-id", "position-id"]),
    ($setup.modals[$setup.EModals.UPDATE_POSITION])
      ? (_openBlock(), _createBlock(_component_UpdatePositionModal, {
          key: 1,
          "schedule-id": $setup.scheduleIdToShowPositions,
          "position-id": $setup.positionIdToUpdate
        }, null, 8, ["schedule-id", "position-id"]))
      : _createCommentVNode("", true)
  ], 64))
}