import { defineStore } from 'pinia'
import Network from '@/Network'
import { GroupResponse, ListGroupsResponse } from '@/Network/Types/Responses/GroupResponses'
import { CreateGroupRequest } from '@/Network/Types/Requests/GroupRequests'

export type GroupState = {
  groups: ListGroupsResponse | undefined
  filteredGroups: GroupResponse[]
  isLoadingGetAllGroups: boolean
}

export type Filter = {
  searchTerm?: string
}

export const useGroupStore = defineStore('group', {
  state: (): GroupState => ({
    groups: undefined,
    filteredGroups: [],
    isLoadingGetAllGroups: false
  }),
  actions: {
    async getAllGroups(): Promise<void> {
      try {
        this.isLoadingGetAllGroups = true
        const response = await Network.group.list()
        this.groups = response
        this.filteredGroups = response.data
      } finally {
        this.isLoadingGetAllGroups = false
      }
    },
    async create(payload: CreateGroupRequest) {
      await Network.group.create(payload)
    },
    async remove(groupId: number): Promise<void> {
      await Network.group.remove(groupId)
    },
    async update({
      groupId,
      payload
    }: {
      groupId: number
      payload: CreateGroupRequest
    }): Promise<void> {
      await Network.group.update(groupId, payload)
    },
    applyFilters(filters: Filter) {
      const { searchTerm } = filters
      if (searchTerm) {
        const lowerCaseSearchTerm = searchTerm.toLowerCase()
        this.filteredGroups =
          this.groups?.data.filter((group) => {
            return group.name.toLowerCase().includes(lowerCaseSearchTerm)
          }) || []
        return
      }
      this.filteredGroups = this.groups?.data || []
    }
  }
})
