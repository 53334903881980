<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 my-8">
      <Input
        v-model="userName"
        :value="userName"
        @input="onChangeUserName"
        :isError="isErrorUserName"
        errorMessage="Nome de usuário inválido"
        width="100%"
        label="Nome"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        required
      />

      <Input
        v-model="cpf"
        :value="cpf"
        @input="onChangeCpf"
        :isError="isErrorCpf"
        errorMessage="CPF inválido!"
        pattern="^\d{3}\.\d{3}\.\d{3}-\d{2}$"
        type="text"
        width="100%"
        label="CPF"
        mask="###.###.###-##"
        required
      />

      <Input
        v-model="birthDate"
        :value="birthDate"
        @input="onChangeBirthDate"
        :isError="isErrorBirthDate"
        errorMessage="Data de nascimento inválida!"
        width="100%"
        type="text"
        pattern="^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$"
        label="Data de Nascimento"
        mask="##/##/####"
        required
      />

      <Input
        v-model="email"
        :value="email"
        @input="onChangeEmail"
        :isError="isErrorEmail"
        errorMessage="E-mail inválido!"
        pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
        type="email"
        width="100%"
        label="Email"
        required
      />

      <Input
        v-model="phoneNumber"
        :value="phoneNumber"
        @input="onChangePhone"
        :isError="isErrorPhoneNumber"
        errorMessage="Telefone inválido"
        width="100%"
        type="tel"
        pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
        label="Telefone Principal"
        mask="(##) #####-####"
        required
      />
    </div>
  </div>

  <div class="flex justify-end mt-10">
    <FilledButton text="Salvar alterações" width="180px" @click="saveChanges" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { UserDataResponse } from '@/Network/Types/Responses/UserResponse'

const props = defineProps<{ userData?: UserDataResponse | null }>()
const emit = defineEmits(['updateUserData'])

const userName = ref('')
const email = ref('')
const cpf = ref('')
const birthDate = ref('')
const phoneNumber = ref('')

const isErrorUserName = ref(false)
const isErrorCpf = ref(false)
const isErrorBirthDate = ref(false)
const isErrorEmail = ref(false)
const isErrorPhoneNumber = ref(false)

watch(
  () => props.userData,
  (newData) => {
    if (newData) {
      userName.value = newData.user?.name || ''
      email.value = newData.user?.email || ''
      cpf.value = newData.user?.cpf ? formatCPF(newData.user.cpf) : ''
      birthDate.value = newData.user?.birthDate ? formatDate(newData.user.birthDate) : ''
      phoneNumber.value = newData.phoneNumber || ''
    }
  },
  { immediate: true }
)

const onChangeUserName = () => {
  isErrorUserName.value = !userName.value.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/)
}

const onChangeCpf = () => {
  isErrorCpf.value = !cpf.value.match(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)
}

const onChangeBirthDate = () => {
  isErrorBirthDate.value = !birthDate.value.match(
    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
  )
}

const onChangeEmail = () => {
  isErrorEmail.value = !email.value.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/)
}

const onChangePhone = () => {
  isErrorPhoneNumber.value = !phoneNumber.value.match(/\(\d{2}\)\s\d{4,5}-\d{4}/)
}

const emitUserData = () => {
  console.log('cpf ', cpf.value)
  emit('updateUserData', {
    name: userName.value,
    email: email.value,
    cpf: cpf.value,
    birthDate: formatBirthDate(birthDate.value),
    phoneNumber: phoneNumber.value
  })
}

const saveChanges = () => {
  emitUserData()
}

const formatCPF = (cpf: string) => {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

const formatBirthDate = (date: string): string | null => {
  if (!date) return null

  if (/^\d{8}$/.test(date)) {
    const day = date.substring(0, 2)
    const month = date.substring(2, 4)
    const year = date.substring(4, 8)

    return `${year}-${month}-${day}`
  }

  if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
    const [day, month, year] = date.split('/')

    return `${year}-${month}-${day}`
  }

  return date
}

const formatDate = (date: string) => {
  if (/^\d{8}$/.test(date)) {
    const day = date.substring(0, 2)
    const month = date.substring(2, 4)
    const year = date.substring(4, 8)
    return `${day}/${month}/${year}`
  }

  const parsedDate = new Date(date)
  if (!isNaN(parsedDate.getTime())) {
    return parsedDate.toLocaleDateString('pt-BR')
  }

  return date
}
</script>
