<template>
  <div class="station-form-category-container">
    <span class="station-form-category-title">Localização e escala</span>
    <div class="station-form-category-content">
      <div class="station-form-category-columns">
        <v-autocomplete
          @update:model-value="setCompany"
          :items="listCompanies"
          :model-value="company ? String(company) : undefined"
          class="w-full"
          color="primary20"
          label="Empresa"
          theme="light"
          variant="outlined"
          density="comfortable"
          :error-messages="companyErr"
        />
        <v-autocomplete
          @update:model-value="setHealthUnit"
          :items="listHealthUnits"
          :model-value="healthUnit ? String(healthUnit) : undefined"
          :disabled="!company"
          class="w-full"
          color="primary20"
          label="Unidade"
          variant="outlined"
          density="comfortable"
          :error-messages="healthUnitErr"
        />
      </div>
      <div class="station-form-category-columns">
        <v-autocomplete
          @update:model-value="setSchedule"
          :items="listSchedules"
          :model-value="schedule ? String(schedule) : undefined"
          :disabled="!healthUnit"
          class="max-w-1/2"
          color="primary20"
          label="Escala"
          theme="light"
          variant="outlined"
          density="comfortable"
          :error-messages="scheduleErr"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'
import { storeToRefs } from 'pinia'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'
import { usePositionStore } from '@/pinia-store/usePositionStore'
import { CompanyDoctorInviteStatusEnum } from '@/Network/Types/Requests/CompanyRequests'

defineProps([
  'company',
  'healthUnit',
  'schedule',
  'companyErr',
  'healthUnitErr',
  'scheduleErr',
  'doctor'
])
const emit = defineEmits([
  'update:company',
  'update:healthUnit',
  'update:schedule',
  'update:doctor'
])

const healthUnitStore = useHealthUnitStore()
const scheduleStore = useScheduleStore()
const doctorStore = useDoctorStore()
const { listCompanies } = storeToRefs(useCompanyStore())
const { listHealthUnits } = storeToRefs(useHealthUnitStore())
const { listSchedules } = storeToRefs(useScheduleStore())

const positionStore = usePositionStore()

const setCompany = async (v: string) => {
  const companyId = Number(v)
  emit('update:company', Number(companyId))
  emit('update:healthUnit', undefined)
  emit('update:schedule', undefined)
  emit('update:doctor', undefined)
  healthUnitStore.applyFilters({ companies: [String(companyId)] }, false)
  doctorStore.doctorQuery.limit = undefined
  doctorStore.doctorQuery.offset = undefined
  await doctorStore.applyFilters(
    {
      search: '',
      names: [],
      crms: [],
      crmUFs: [],
      companies: [String(companyId)],
      inviteStatus: [CompanyDoctorInviteStatusEnum.ACCEPTED, CompanyDoctorInviteStatusEnum.PENDING]
    },
    false
  )
}

const setHealthUnit = (v: string) => {
  const healthUnitId = Number(v)
  emit('update:healthUnit', healthUnitId)
  emit('update:schedule', undefined)
  scheduleStore.setSchedulesByHealthUnit(healthUnitId)
}

const setSchedule = async (v: string) => {
  const scheduleId = Number(v)
  await positionStore.getByScheduleId(scheduleId)
  emit('update:schedule', Number(scheduleId))
}
</script>
