<template>
  <div
    :title="unit.name"
    class="w-full hover:shadow-lg px-4 py-4 rounded-[8px] my-0 grid grid-cols-12 border-2 gap-2 border-[#F0F0F4] hover:border-[#2F7F91] transition ease-in-out cursor-pointer select-none"
  >
    <v-avatar class="col-span-2 sm:col-span-3 shrink-0 w-[40px]" color="primary">
      <span class="text-md font-bold">{{ getInitalsName(unit.name || 'ME') }}</span>
    </v-avatar>
    <div class="col-span-9 sm:col-span-8 text-left">
      <p class="font-bold truncate">{{ unit.name }}</p>
      <p class="text-xs">{{ unit.mainPhone?.phoneNumber }}</p>
    </div>
    <v-icon
      v-if="unit.isAdmin"
      class="flex items-center justify-center mt-auto col-span-1"
      size="25"
      color="primary"
      :icon="mdiPencilBox"
    />
  </div>
</template>

<script setup lang="ts">
import { SimpleHealthUnitResponse } from '@/Network/Types/Responses/HealthUnitResponses'
import { defineProps } from 'vue'
import { mdiPencilBox } from '@mdi/js'
defineProps<{
  unit: SimpleHealthUnitResponse
}>()

const getInitalsName = (name: string) => {
  return name.substring(0, 2).toUpperCase()
}
</script>
