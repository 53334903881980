<template>
  <!-- <div class="flex justify-start gap-1 flex-wrap"> -->
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="6"
      lg="3"
      xl="3"
      v-for="(company, index) in myCompanies"
      :key="index"
    >
      <Card :company="company" />
    </v-col>
  </v-row>
  <!-- </div> -->

  <!-- <div class="flex justify-end mt-10">
      <FilledButton
        text="Salvar alterações"
        width="180px"
        @click="() => toast.success('Dados alterados com sucesso!')"
      />
    </div> -->
</template>

<script>
import { onMounted, computed } from 'vue'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { useToast } from 'vue-toastification'
import { Card } from '../atoms'

export default {
  name: 'CompaniesPanel',
  components: {
    Card
  },
  setup() {
    const toast = useToast()

    const companyStore = useCompanyStore()
    const myCompanies = computed(() => companyStore.myCompanies)

    onMounted(async () => {
      try {
        await companyStore.getMyCompanies()
      } catch (error) {
        console.error('Erro ao carregar empresas:', error)
        toast.error('Erro ao carregar empresas.')
      }
    })

    return { toast, myCompanies }
  }
}
</script>
