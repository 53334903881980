<template>
  <div class="w-full flex flex-col gap-5">
    <div class="flex flex-wrap justify-between items-center">
      <h1 class="title font-bold">Perfil e configurações</h1>
    </div>

    <div>
      <div class="flex items-center mb-2">
        <div class="w-12 h-12 relative max-w-max cursor-pointer" @click="() => input.click()">
          <v-avatar color="primary" class="mr-2">
            <span class="text-md font-bold">{{ getInitalsName(userName) }}</span>
          </v-avatar>

          <input @change="file" class="absolute w-0 h-0 hidden" type="file" ref="input" />
        </div>

        <p class="ml-4">{{ userName }}</p>
      </div>

      <ul class="tabs w-full max-w-full flex flex-grow overflow-x-auto text-white select-none">
        <li>
          <button @click="() => (tab = 1)" :class="tab === 1 ? activeClass : inactiveClass">
            Dados
          </button>
        </li>

        <li>
          <button @click="() => (tab = 2)" :class="tab === 2 ? activeClass : inactiveClass">
            Empresas
          </button>
        </li>

        <li>
          <button @click="() => (tab = 3)" :class="tab === 3 ? activeClass : inactiveClass">
            Editar senha
          </button>
        </li>
      </ul>

      <div>
        <div v-show="tab === 1">
          <DataPanel :userData="userData" @updateUserData="handleUserDataUpdate" />
        </div>
        <div v-show="tab === 2">
          <CompaniesPanel />
        </div>

        <div v-show="tab === 3">
          <PasswordPanel :userData="userData" @updateUserPassword="handleUserPasswordUpdate" />
        </div>
      </div>
    </div>

    <Spinner
      v-if="userStore.isLoading"
      :color="'primary'"
      :size="'5rem'"
      :width="'0.5rem'"
      class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50 z-50"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useToast } from 'vue-toastification'
import DataPanel from './components/DataPanel/Index.vue'
import CompaniesPanel from './components/CompaniesPanel/Index.vue'
import PasswordPanel from './components/PasswordPanel/Index.vue'
import { useUserStore } from '@/pinia-store/useUsersStore'
import Spinner from '@/components/others/Spinner.vue'

const userStore = useUserStore()
const userData = computed(() => userStore.userData)

const userName = computed(() => {
  return userStore.userData?.user?.name || 'Usuário'
})

const input = ref()
const tab = ref(1)
const fileValue = ref()
const toast = useToast()

onMounted(async () => {
  await userStore.getUserData()
})

const file = (event) => {
  if (FileReader && event.target.files && event.target.files.length) {
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
      if (!reader.result) return

      fileValue.value = reader.result
    }

    toast.success('Foto de perfil alterada com sucesso!')
  }
}

const handleUserDataUpdate = async (updatedData) => {
  console.log('Dados recebidos do DataPanel:', updatedData)

  try {
    const updatedUser = await userStore.updateUserData(updatedData)

    console.log('Usuário atualizado:', updatedUser)

    userStore.userData = updatedUser

    toast.success('Dados do usuário atualizados com sucesso!')
  } catch (error) {
    console.error('Erro ao atualizar os dados do usuário:', error)
    toast.error('Erro ao atualizar os dados. Tente novamente!')
  }
}

const handleUserPasswordUpdate = async (updatedPassword: {
  password?: string
  repeatPassword?: string
}) => {
  try {
    const { password, repeatPassword } = updatedPassword

    if (
      typeof password !== 'string' ||
      password.trim() === '' ||
      typeof repeatPassword !== 'string' ||
      repeatPassword.trim() === ''
    ) {
      toast.error('Todos os campos são obrigatórios!')
      return
    }

    await userStore.changePassword(password, repeatPassword)

    toast.success('Senha do usuário atualizada com sucesso!')
  } catch (error) {
    console.error('Erro ao atualizar a senha do usuário:', error)
    toast.error('Erro ao atualizar a senha. Tente novamente!')
  }
}

const getInitalsName = (name: string) => {
  return name.substring(0, 2).toUpperCase()
}

const activeClass = ref('inline-block py-4 text-black font-semibold border-b border-primary')
const inactiveClass = ref('inline-block py-4 text-gray-500')
</script>

<style scoped>
.title {
  font-size: 1.25rem;
}

.tabs {
  border-bottom-width: 2px;
}

.tabs {
  gap: 60px;
}

.tabs > li > button {
  min-width: 80px;
  text-align: start;
}
</style>
