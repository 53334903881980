// src/stores/useHealthUnitStore.ts
import { defineStore } from 'pinia'
import { router } from '@/router'
import { useRoute, LocationQuery } from 'vue-router'
import Network from '@/Network'
import { FiltersHealthUnits } from '@/Network/Types/Requests/HealthUnitRequests'
import { ListHealthUnitsResponse } from '@/Network/Types/Responses/HealthUnitResponses'
import { SelectOption } from '@/types'
import { useToast } from 'vue-toastification'
import { useFeatureFlags } from '@/hooks/useFeatureFlags'

interface HealthUnitFilters {
  search: string
  names: string[]
  cnpjs: string[]
  ufs: string[]
  companies: string[]
}

interface ListHealthUnits {
  title: string
  value: string
  subtitle: string
}

export type HealthUnitState = {
  healthUnitsAdminAccess: SelectOption[]
  isLoadingSelectFetch: boolean
  healthUnits: ListHealthUnitsResponse | undefined
  listHealthUnits: ListHealthUnits[]
  healthUnitsMap: Map<number, string>
  isLoading: boolean
  isError: boolean
  errorMessage: string | null
  healthUnitQuery: FiltersHealthUnits
  totalHealthUnits: number
  page: number
}

const DEFAULT_HEALTH_UNIT_LIMIT = 15
export const useHealthUnitStore = defineStore('healthUnit', {
  state: (): HealthUnitState => ({
    healthUnitsAdminAccess: [],
    isLoadingSelectFetch: false,
    healthUnits: undefined,
    listHealthUnits: [],
    healthUnitsMap: new Map(),
    isLoading: false,
    isError: false,
    errorMessage: null,
    healthUnitQuery: {
      search: '',
      names: '',
      cnpjs: '',
      ufs: '',
      companies: '',
      limit: DEFAULT_HEALTH_UNIT_LIMIT,
      offset: 0
    },
    totalHealthUnits: 0,
    page: 1
  }),
  getters: {
    isFilterApplied(state): boolean {
      return (
        (state.healthUnitQuery.search?.trim().length ?? 0) > 0 ||
        (state.healthUnitQuery.names?.trim().length ?? 0) > 0 ||
        (state.healthUnitQuery.cnpjs?.trim().length ?? 0) > 0 ||
        (state.healthUnitQuery.ufs?.trim().length ?? 0) > 0 ||
        (state.healthUnitQuery.companies?.trim().length ?? 0) > 0
      )
    }
  },
  actions: {
    async getHealthUnitOptions(adminView: boolean = false): Promise<SelectOption[]> {
      const toast = useToast()

      try {
        this.isLoadingSelectFetch = true
        if (adminView) {
          const response = await Network.healthUnit.listAdminAccess()
          this.healthUnitsAdminAccess = response
          return response
        }
        const response = await Network.healthUnit.listAdminAccess()
        return response
      } catch (error) {
        toast.error('Erro ao buscar unidades, fale com um administrador.')
        return []
      } finally {
        this.isLoadingSelectFetch = false
      }
    },
    deserializeFilters(query: LocationQuery): HealthUnitFilters {
      return {
        search: Array.isArray(query.search) ? String(query.search[0]) : (query.search ?? ''),
        names: Array.isArray(query.names)
          ? query.names
              .filter((n): n is string => typeof n === 'string')
              .join(',')
              .split(',')
          : typeof query.names === 'string'
            ? query.names.split(',')
            : [],
        cnpjs: Array.isArray(query.cnpjs)
          ? query.cnpjs
              .filter((c): c is string => typeof c === 'string')
              .join(',')
              .split(',')
          : typeof query.cnpjs === 'string'
            ? query.cnpjs.split(',')
            : [],
        ufs: Array.isArray(query.ufs)
          ? query.ufs
              .filter((u): u is string => typeof u === 'string')
              .join(',')
              .split(',')
          : typeof query.ufs === 'string'
            ? query.ufs.split(',')
            : [],
        companies: Array.isArray(query.companies)
          ? query.companies
              .filter((c): c is string => typeof c === 'string')
              .join(',')
              .split(',')
          : typeof query.companies === 'string'
            ? query.companies.split(',')
            : []
      }
    },
    serializeFilters(): Record<string, string> {
      const params = new URLSearchParams()

      if (this.healthUnitQuery.search) {
        params.set('search', this.healthUnitQuery.search)
      }
      if (this.healthUnitQuery.names) {
        params.set('names', this.healthUnitQuery.names)
      }
      if (this.healthUnitQuery.cnpjs) {
        params.set('cnpjs', this.healthUnitQuery.cnpjs)
      }
      if (this.healthUnitQuery.ufs) {
        params.set('ufs', this.healthUnitQuery.ufs)
      }
      if (this.healthUnitQuery.companies) {
        params.set('companies', this.healthUnitQuery.companies)
      }

      if (this.healthUnitQuery.limit) {
        params.set('limit', this.healthUnitQuery.limit.toString())
      }
      if (this.healthUnitQuery.offset !== undefined) {
        params.set('offset', this.healthUnitQuery.offset.toString())
      }

      const queryObject: Record<string, string> = {}
      params.forEach((value, key) => {
        queryObject[key] = value
      })

      return queryObject
    },
    updateQueryString() {
      const queryString = this.serializeFilters()
      router.replace({ query: queryString })
    },

    async setFiltersFromQuery(): Promise<FiltersHealthUnits> {
      const route = useRoute()
      const query = route.query

      const filters: HealthUnitFilters = this.deserializeFilters(query)

      this.healthUnitQuery.search = filters.search
      this.healthUnitQuery.names = filters.names.length > 0 ? filters.names.join(',') : ''
      this.healthUnitQuery.cnpjs = filters.cnpjs.length > 0 ? filters.cnpjs.join(',') : ''
      this.healthUnitQuery.ufs = filters.ufs.length > 0 ? filters.ufs.join(',') : ''
      this.healthUnitQuery.companies =
        filters.companies.length > 0 ? filters.companies.join(',') : ''

      this.healthUnitQuery.limit = query.limit ? Number(query.limit) : DEFAULT_HEALTH_UNIT_LIMIT
      this.healthUnitQuery.offset = query.offset ? Number(query.offset) : 0
      const { flags } = useFeatureFlags()
      if (!flags.enabledPermissions) await this.fetchHealthUnits()
      return this.healthUnitQuery
    },
    async fetchHealthUnits(): Promise<void> {
      try {
        this.isError = false
        this.errorMessage = null
        this.isLoading = true
        const response = await Network.healthUnit.list(this.healthUnitQuery)
        this.healthUnits = response
        this.listHealthUnits = (this.healthUnits?.data ?? []).map((h) => {
          const unit = {
            title: h.name,
            value: String(h.id),
            subtitle: h.company.name
          }
          this.healthUnitsMap.set(+unit.value, unit.title)
          return unit
        })
        this.totalHealthUnits = response.count
      } catch (error) {
        console.error('Erro ao obter unidades de saúde:', error)
        this.healthUnits = { count: 0, data: [] }
        this.listHealthUnits = []
        this.totalHealthUnits = 0

        this.isError = true

        if (error instanceof Error) {
          this.errorMessage = error.message
        } else {
          this.errorMessage = 'Erro desconhecido'
        }
      } finally {
        this.isLoading = false
      }
    },
    async applyFilters(
      newFilters: Partial<HealthUnitFilters>,
      isUpdateUrlQueryString: boolean = true
    ): Promise<FiltersHealthUnits> {
      if (newFilters.search !== undefined) {
        this.healthUnitQuery.search = newFilters.search
      }
      if (newFilters.names !== undefined) {
        this.healthUnitQuery.names = newFilters.names.join(',')
      }
      if (newFilters.cnpjs !== undefined) {
        this.healthUnitQuery.cnpjs = newFilters.cnpjs.join(',')
      }
      if (newFilters.ufs !== undefined) {
        this.healthUnitQuery.ufs = newFilters.ufs.join(',')
      }
      if (newFilters.companies !== undefined) {
        this.healthUnitQuery.companies = newFilters.companies.join(',')
      }

      this.healthUnitQuery.offset = 0
      if (isUpdateUrlQueryString) {
        this.updateQueryString()
      }

      const { flags } = useFeatureFlags()
      if (!flags.enabledPermissions) this.fetchHealthUnits()
      return this.healthUnitQuery
    },
    async clearFilters(): Promise<FiltersHealthUnits> {
      this.healthUnitQuery = {
        search: '',
        names: '',
        cnpjs: '',
        ufs: '',
        companies: '',
        limit: DEFAULT_HEALTH_UNIT_LIMIT,
        offset: 0
      }
      this.updateQueryString()

      const { flags } = useFeatureFlags()
      if (!flags.enabledPermissions) this.fetchHealthUnits()

      return this.healthUnitQuery
    },
    async changePage(page: number): Promise<FiltersHealthUnits> {
      this.page = page
      this.healthUnitQuery.offset = (page - 1) * this.healthUnitQuery.limit!
      this.updateQueryString()
      const { flags } = useFeatureFlags()
      if (!flags.enabledPermissions) await this.fetchHealthUnits()

      return this.healthUnitQuery
    },
    calculateOffSet(): number {
      return (this.page - 1) * this.healthUnitQuery.limit!
    },

    getPageCount(): number {
      return Math.ceil(this.totalHealthUnits / this.healthUnitQuery.limit!)
    },
    async getAllHealthUnits(filters?: FiltersHealthUnits): Promise<ListHealthUnits[]> {
      if (filters) {
        this.healthUnitQuery = filters
      }

      this.fetchHealthUnits()

      return this.listHealthUnits
    },

    getHealthUnitNameById(id: number): ListHealthUnits | undefined {
      return this.listHealthUnits.find((healthUnit) => healthUnit.value === String(id))
    },
    async setHealthUnitsByCompany(companyId: number | null) {
      if (!companyId) {
        this.listHealthUnits = []
        return
      }
      await this.getAllHealthUnits({
        limit: 1000,
        companies: String(companyId)
      })

      const healthUnits = this.healthUnits?.data || []
      this.listHealthUnits = healthUnits.map((h) => ({
        title: h.name,
        value: String(h.id),
        subtitle: h.company.name
      }))
    }
  }
})
