<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-3 my-8 gap-4 max-w-full md:max-w-3xl">
      <Input
        v-model="password"
        :isError="isErrorPassword"
        @input="onChangePassword"
        errorMessage="Senha atual inválida"
        width="100%"
        label="Senha atual"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="password"
        required
      />

      <Input
        v-model="newPassword"
        :isError="isErrorNewPassword"
        @input="onChangeNewPassword"
        errorMessage="Nova senha inválida"
        width="100%"
        label="Nova senha"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="newPassword"
        required
      />

      <Input
        v-model="reWriteNewPassword"
        :isError="isErrorReWriteNewPassword"
        @input="onChangeReWriteNewPassword"
        errorMessage="Nova senha inválida"
        width="100%"
        label="Confirmar senha"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="reWriteNewPassword"
        required
      />
    </div>
  </div>

  <div class="flex justify-end mt-10">
    <FilledButton text="Salvar alterações" width="180px" @click="saveChanges" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { UserDataResponse } from '@/Network/Types/Responses/UserResponse'

const props = defineProps<{ userData?: UserDataResponse | null }>()
const emit = defineEmits(['updateUserPassword'])

const email = ref('')
const password = ref('')
const newPassword = ref('')
const reWriteNewPassword = ref('')

const isErrorPassword = ref(false)
const isErrorNewPassword = ref(false)
const isErrorReWriteNewPassword = ref(false)

watch(
  () => props.userData,
  (newData) => {
    if (newData) {
      email.value = newData.user?.email || ''
    }
  },
  { immediate: true }
)

const emitUserPassword = (updatedPassword: { password?: string; repeatPassword?: string }) => {
  emit('updateUserPassword', updatedPassword)
}

const saveChanges = async () => {
  emitUserPassword({
    password: newPassword.value,
    repeatPassword: reWriteNewPassword.value
  })

  password.value = ''
  newPassword.value = ''
  reWriteNewPassword.value = ''
}

const onChangePassword = () => {
  isErrorPassword.value = password.value.trim().length < 6
}

const onChangeNewPassword = () => {
  isErrorNewPassword.value = newPassword.value.trim().length < 6
}

const onChangeReWriteNewPassword = () => {
  isErrorReWriteNewPassword.value = newPassword.value !== reWriteNewPassword.value
}
</script>
