import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { CreateHealthUnit, FiltersHealthUnits } from '../Types/Requests/HealthUnitRequests'
import {
  HealthUnitResponse,
  ListHealthUnitsResponse,
  SimpleHealthUnitResponse
} from '../Types/Responses/HealthUnitResponses'
import { SelectOption } from '@/types'
export default class HealthUnitService extends Service {
  prefix: string

  constructor() {
    super()

    this.prefix = '/healthUnits'
  }

  async listAdminAccess(): Promise<SelectOption[]> {
    const { data } = await request.get<SelectOption[]>(`${this.getCompletePath()}/list-admin`)
    return data
  }

  async listViewAccess(): Promise<SelectOption[]> {
    const { data } = await request.get<SelectOption[]>(`${this.getCompletePath()}/list-view`)
    return data
  }

  async create(companyId: number, payload: CreateHealthUnit): Promise<void> {
    await request.post<HealthUnitResponse>(
      `${this.getCompletePath()}/company/${companyId}`,
      payload
    )
  }

  async update(healthUnitId: number, payload: CreateHealthUnit): Promise<void> {
    await request.patch(`${this.getCompletePath()}/${healthUnitId}`, payload)
  }

  async list(filters?: FiltersHealthUnits): Promise<ListHealthUnitsResponse> {
    try {
      const { data } = await request.get<ListHealthUnitsResponse>(this.getCompletePath(), filters)
      return data
    } catch (e) {
      return {
        count: 0,
        data: []
      }
    }
  }

  async listV2(
    filters?: FiltersHealthUnits
  ): Promise<{ data: SimpleHealthUnitResponse[]; count: number }> {
    const page = (filters?.page || 1) - 1
    const limit = filters?.limit || 20
    const offset = page * limit
    if (filters) filters.offset = offset

    const { data } = await request.get<{ data: SimpleHealthUnitResponse[]; count: number }>(
      this.getCompletePath(),
      filters
    )
    return data
  }
}
