<template>
  <div class="flex flex-row justify-center items-center gap-2">
    <v-icon :icon="mdiAlert" :size="iconSize" :color="iconColor"></v-icon>
    <span>Este recurso não foi compartilhado.</span>
  </div>
</template>

<script lang="ts">
import { mdiAlert } from '@mdi/js'

export default {
  props: {
    iconSize: {
      type: String,
      default: '25px'
    },
    iconColor: {
      type: String,
      default: 'primary'
    }
  },
  setup() {
    return {
      mdiAlert
    }
  }
}
</script>

<style scoped></style>
