<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" :hasFooterBorder="true">
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Exportar relatório de apontamentos do médico</strong>
      </div>
    </template>

    <VDatePicker v-model:range="range" mode="date" expanded />

    <div class="rounded border border-primary30 mt-2 text-primary">
      de {{ formatDateToString(range.start) }} até {{ formatDateToString(range.end) }}
    </div>

    <div class="max-h-[250px] overflow-y-auto">
      <v-row class="text-left">
        <v-col class="text-sm text-primary" v-if="unitsFilter?.length" cols="12">
          <div class="mb-1 font-semibold">Unidades:</div>
          <div class="flex flex-wrap gap-2">
            <span
              v-for="unit in limitedUnits"
              :key="unit.id"
              class="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-xs"
            >
              {{ unit.text }}
            </span>
            <button
              v-if="unitsFilter.length > unitLimit && !expandedUnits"
              @click="expandedUnits = true"
              class="text-blue-500 text-xs"
            >
              Ver Mais
            </button>
            <button
              v-if="unitsFilter.length > unitLimit && expandedUnits"
              @click="expandedUnits = false"
              class="text-blue-500 text-xs"
            >
              Ver Menos
            </button>
          </div>
        </v-col>

        <v-col class="text-sm text-primary" v-if="schedulesFilter?.length" cols="12">
          <div class="mb-1 font-semibold">Escalas:</div>
          <div class="flex flex-wrap gap-2">
            <span
              v-for="schedule in limitedSchedules"
              :key="schedule.id"
              class="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-xs"
            >
              {{ schedule.text }}
            </span>
            <button
              v-if="schedulesFilter.length > scheduleLimit && !expandedSchedules"
              @click="expandedSchedules = true"
              class="text-blue-500 text-xs"
            >
              Ver Mais
            </button>
            <button
              v-if="schedulesFilter.length > scheduleLimit && expandedSchedules"
              @click="expandedSchedules = false"
              class="text-blue-500 text-xs"
            >
              Ver Menos
            </button>
          </div>
        </v-col>

        <v-col class="text-sm text-primary" v-if="hoursFilter?.length" cols="12">
          <div class="mb-1 font-semibold">Horários:</div>
          <div class="flex flex-wrap gap-2">
            <span
              v-for="hour in hoursFilter"
              :key="hour.text"
              class="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-xs"
            >
              {{ hour.text }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>

    <template #footer>
      <div class="flex gap-5 justify-end mt-4 px-3">
        <filled-button @click="handleExport" text="Exportar" height="52px" width="180px" />
      </div>
    </template>
  </modal-vue>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { startOfMonth, endOfMonth } from 'date-fns'
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { formatDateToString } from '@/utils/date'
import Network from '@/Network'
import { useToast } from 'vue-toastification'
import { useStationStore } from '@/pinia-store/useStationStore'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import axios from 'axios'

const modalName = EModals.EXPORT_DASHBOARD_EXCEL
const modalStore = useModalStore()
const { modals } = storeToRefs(modalStore)

const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
}

const healthUnitStore = useHealthUnitStore()
const scheduleStore = useScheduleStore()
const { healthUnitsMap, listHealthUnits } = storeToRefs(healthUnitStore)
const { listSchedules, schedulesMap } = storeToRefs(scheduleStore)
const stationStore = useStationStore()
const { listStationsQuery } = storeToRefs(stationStore)

const today = new Date()
const firstDateOfMonth = startOfMonth(today)
const lastDateOfMonth = endOfMonth(today)
const range = ref({
  start: firstDateOfMonth,
  end: lastDateOfMonth
})

const toast = useToast()

const hoursFilter = computed(() => {
  if (listStationsQuery.value?.startHour && listStationsQuery.value?.endHour) {
    const startHours = listStationsQuery.value.startHour.split(',')
    const endHours = listStationsQuery.value.endHour.split(',')
    if (startHours.length === 0 || endHours.length === 0 || startHours.length !== endHours.length) {
      return []
    }
    return startHours.map((startHour, i) => ({
      text: `${startHour}-${endHours[i]}`
    }))
  } else if (listStationsQuery.value?.startDate && listStationsQuery.value?.endDate) {
    const startDate = new Date(listStationsQuery.value.startDate)
    const endDate = new Date(listStationsQuery.value.endDate)
    const startHour = startDate.getHours().toString().padStart(2, '0')
    const startMinute = startDate.getMinutes().toString().padStart(2, '0')
    const endHour = endDate.getHours().toString().padStart(2, '0')
    const endMinute = endDate.getMinutes().toString().padStart(2, '0')
    return [
      {
        text: `${startHour}:${startMinute}-${endHour}:${endMinute}`
      }
    ]
  }
  return []
})

const unitsFilter = computed(() => {
  if (
    listStationsQuery.value &&
    listStationsQuery.value.healthUnits &&
    listStationsQuery.value.healthUnits.length > 0
  ) {
    return listStationsQuery.value.healthUnits.map((u) => ({
      id: u,
      text: healthUnitsMap.value.get(+u)
    }))
  } else {
    return listHealthUnits.value.map((unit) => ({
      id: unit.value,
      text: unit.title
    }))
  }
})

const schedulesFilter = computed(() => {
  if (
    listStationsQuery.value &&
    listStationsQuery.value.schedules &&
    listStationsQuery.value.schedules.length > 0
  ) {
    return listStationsQuery.value.schedules.map((u) => ({
      id: u,
      text: schedulesMap.value.get(+u)
    }))
  } else {
    return listSchedules.value.map((schedule) => ({
      id: schedule.value,
      text: schedule.title
    }))
  }
})

const expandedUnits = ref(false)
const expandedSchedules = ref(false)
const unitLimit = 3
const scheduleLimit = 3

const limitedUnits = computed(() => {
  return expandedUnits.value ? unitsFilter.value : unitsFilter.value.slice(0, unitLimit)
})

const limitedSchedules = computed(() => {
  return expandedSchedules.value
    ? schedulesFilter.value
    : schedulesFilter.value.slice(0, scheduleLimit)
})

const handleExport = async () => {
  try {
    const filename = `relatorio-plantoes-${formatDateToString(
      range.value.start
    )}-${formatDateToString(range.value.end)}.xlsx`

    const link = await Network.station.exportRelatoryXlsx({
      startDate: range.value.start,
      endDate: range.value.end,
      filename
    })

    document.body.appendChild(link)
    link.click()
    toast.success('Relatório exportado com sucesso')
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      toast.warning('Nenhum apontamento encontrado dentro do período especificado')
      return
    }
    toast.error('Erro ao exportar relatório, contate um administrador!')
  } finally {
    handleToggleModal()
  }
}
</script>
