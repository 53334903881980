import { format, parse } from 'date-fns'
export const formatDateToString = (date: Date) => {
  return format(date, 'dd/MM/yyyy')
}

export const formatStringToDate = (dateStr: string): Date => {
  return parse(dateStr, 'dd/MM/yyyy', new Date())
}

export function parseDateStringToISO(dateString?: string): string | undefined {
  if (!dateString) return undefined
  // Verifica se o formato é dd/MM/yyyy
  const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/
  const match = dateString.match(datePattern)

  if (!match) {
    throw new Error('Formato de data inválido. Use o formato dd/MM/yyyy.')
  }

  // Extrai o dia, mês e ano
  const [, day, month, year] = match

  // Retorna no formato ISO
  return `${year}-${month}-${day}`
}

export const daysWeekObj = {
  '0': 'Domingo',
  '1': 'Segunda',
  '2': 'Terça',
  '3': 'Quarta',
  '4': 'Quinta',
  '5': 'Sexta',
  '6': 'Sábado'
}
