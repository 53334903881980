import { ResourceType } from '@/Network/Types/Responses/PermissionResponses'
import { defineStore } from 'pinia'

export enum EModals {
  UPDATE_BULK_STATION = 'UPDATE_BULK_STATION',
  REMOVE_BULK_STATION = 'REMOVE_BULK_STATION',
  FILTER_STATION = 'FILTER_STATION',
  FILTER_DASHBOARD = 'FILTER_DASHBOARD',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  UPDATE_POSITION = 'UPDATE_POSITION',
  REMOVE_SCHEDULE = 'REMOVE_SCHEDULE',
  REMOVE_POSITION = 'REMOVE_POSITION',
  EXPORT_DASHBOARD_EXCEL = 'EXPORT_DASHBOARD_EXCEL',
  EXPORT_DASHBOARD_STATIONS = 'EXPORT_DASHBOARD_STATIONS',
  DOCTOR_SIGNUP = 'DOCTOR_SIGNUP',
  LEGENDS = 'LEGENDS',
  SHARE = 'SHARE',
  CREATE_GROUP_MODAL = 'CREATE_GROUP_MODAL',
  UPDATE_GROUP_MODAL = 'UPDATE_GROUP_MODAL',
  CREATE_USER_MODAL = 'CREATE_USER_MODAL',
  POSITIONS_MODAL = 'POSITIONS_MODAL',
  FILTER_UNIT_MODAL = 'FILTER_UNIT_MODAL'
}

export type shareModalDataType = {
  resourceName: string
  resourceType: ResourceType
  resourceId: number
}

export type ModalState = {
  modals: Record<EModals, boolean>
  metadata: shareModalDataType | undefined
  showAlert: boolean
  alertDescription: string
}

export const useModalStore = defineStore('modal', {
  state: (): ModalState => ({
    modals: {
      [EModals.REMOVE_SCHEDULE]: false,
      [EModals.REMOVE_POSITION]: false,
      [EModals.UPDATE_SCHEDULE]: false,
      [EModals.UPDATE_POSITION]: false,
      [EModals.FILTER_STATION]: false,
      [EModals.UPDATE_BULK_STATION]: false,
      [EModals.REMOVE_BULK_STATION]: false,
      [EModals.EXPORT_DASHBOARD_EXCEL]: false,
      [EModals.EXPORT_DASHBOARD_STATIONS]: false,
      [EModals.DOCTOR_SIGNUP]: false,
      [EModals.FILTER_DASHBOARD]: false,
      [EModals.SHARE]: false,
      [EModals.LEGENDS]: false,
      [EModals.CREATE_GROUP_MODAL]: false,
      [EModals.CREATE_USER_MODAL]: false,
      [EModals.UPDATE_GROUP_MODAL]: false,
      [EModals.POSITIONS_MODAL]: false,
      [EModals.FILTER_UNIT_MODAL]: false
    },
    metadata: undefined,
    alertDescription: '',
    showAlert: false
  }),
  actions: {
    toggleModal(modal: EModals, metadata?: shareModalDataType) {
      const isOpenModal = !this.modals[modal]

      if (metadata && isOpenModal) {
        this.metadata = metadata
      }

      this.modals = { ...this.modals, [modal]: isOpenModal }

      if (!isOpenModal) {
        this.metadata = undefined
      }
    },
    toggleShowAlert() {
      this.showAlert = !this.showAlert
    }
  }
})
