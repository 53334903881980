<template>
  <v-row dense>
    <v-row dense justify="end">
      <v-col cols="12" lg="8" md="7">
        <SearchInput
          v-model="textInput"
          hide-details
          width="100%"
          label="Buscar unidade"
          :searchFunction="changeSearchInput"
          :loading="true"
          height="56px"
        />
      </v-col>
      <v-col cols="12" lg="2" md="2">
        <v-btn
          class="w-full h-[56px] font-bold capitalize"
          color="primary20"
          :prepend-icon="mdiFilter"
          variant="outlined"
          @click="openFilterModal"
        >
          Filtrar
        </v-btn>
      </v-col>
      <v-col cols="12" lg="2" md="3">
        <FilledButton
          @click="handleOpenModal('create')"
          text="Cadastrar unidade"
          height="56px"
          width="100%"
          class="sm:w-auto"
        />
      </v-col>
    </v-row>
  </v-row>
  <v-row v-if="isLoading">
    <v-col cols="12">
      <Spinner
        color="primary"
        class="flex justify-center items-center absolute z-50 inset-0 bg-white bg-opacity-50"
      />
    </v-col>
  </v-row>
  <v-row v-else dense>
    <v-col cols="12">
      <div
        class="md:max-w-[210px] flex items-center justify-center max-h-6 rounded-md bg-gray-50 px-2 py-1 text-sm font-medium text-gray-500 ring-1 ring-inset ring-gray-500/10"
      >
        {{ count }} unidades encontrada
      </div>
    </v-col>
    <v-col cols="12" xl="2" lg="3" md="3" v-for="unit in healthUnits || []" :key="unit.id">
      <CardHospital @mousedown="setHealthUnitToUpdate(unit)" :unit="unit" />
    </v-col>
    <v-col cols="12">
      <v-pagination
        v-if="count > itemsPerPage"
        v-model="filters.page"
        :total-visible="5"
        :length="Math.ceil(count / itemsPerPage)"
        density="comfortable"
        color="primary"
      />
    </v-col>
  </v-row>

  <HealthUnitModal
    v-if="toggleHealthUnitModal"
    :healthUnit="healthUnitToUpdate"
    :modalStateProp="toggleHealthUnitModal"
    @close="closeHealthUnitModal"
    :type="operationModal"
  />

  <teleport to="#app">
    <FilterModal @apply-filters="applyFilters" @clear-filters="clearFilters" />
  </teleport>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import Spinner from '@/components/others/Spinner.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import CardHospital from './components/CardHospital.vue'
import HealthUnitModal from './modals/health.unit.modal.vue'
import FilterModal from './modals/filter.health.unit.modal.vue'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import { SimpleHealthUnitResponse } from '@/Network/Types/Responses/HealthUnitResponses'
import { mdiFilter } from '@mdi/js'
import Network from '@/Network'
import { useToast } from 'vue-toastification'
import { FiltersHealthUnits } from '@/Network/Types/Requests/HealthUnitRequests'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { useRoute, useRouter } from 'vue-router'
import { cleanObject } from '@/utils/object'

const modalStore = useModalStore()
const openFilterModal = () => {
  modalStore.toggleModal(EModals.FILTER_UNIT_MODAL)
}

const operationModal = ref<'create' | 'update'>('create')
const toggleHealthUnitModal = ref(false)
const healthUnitToUpdate = ref<SimpleHealthUnitResponse>()

const handleOpenModal = (operation: 'create' | 'update') => {
  operationModal.value = operation
  toggleHealthUnitModal.value = true
}

const closeHealthUnitModal = () => {
  toggleHealthUnitModal.value = false
}

const setHealthUnitToUpdate = (unit: SimpleHealthUnitResponse) => {
  healthUnitToUpdate.value = unit
  handleOpenModal('update')
}

// FETCH PART
const toast = useToast()
const healthUnits = ref<SimpleHealthUnitResponse[]>([])
const isLoading = ref<boolean>(false)
const count = ref<number>(0)
const fetchHealthUnits = async (filters?: FiltersHealthUnits) => {
  try {
    isLoading.value = true
    const response = await Network.healthUnit.listV2(filters)
    healthUnits.value = response.data
    count.value = response.count
  } catch (error) {
    toast.error('Erro ao buscar unidades')
  } finally {
    isLoading.value = false
  }
}

// FILTERS PART
const router = useRouter()
const route = useRoute()
const itemsPerPage = ref<number>(20)
const filters = ref<FiltersHealthUnits>({
  page: Number(route.query.page) || 1,
  limit: itemsPerPage.value
})
const textInput = ref('')

const changeSearchInput = async () => {
  if (textInput.value) filters.value.search = textInput.value
  else filters.value.search = undefined
  filters.value.page = 1
}

const applyFilters = async (modalFields: {
  search: string
  names: string[]
  cnpjs: string[]
  ufs: string[]
  companies: number[]
}) => {
  filters.value = {
    ...filters.value,
    names: modalFields.names.join(','),
    cnpjs: modalFields.cnpjs.join(','),
    companies: modalFields.companies.join(','),
    ufs: modalFields.ufs.join(','),
    page: 1
  }
}

const clearFilters = async () => {
  textInput.value = ''
  filters.value = {
    page: 1,
    limit: itemsPerPage.value
  }
}

watch(
  filters,
  (newFilters) => {
    const query = cleanObject(newFilters)
    router.push({ query })
  },
  { deep: true }
)

watch(
  () => route.query,
  async () => {
    const payload = cleanObject(filters.value)
    await fetchHealthUnits(payload)
  },
  { immediate: true }
)
</script>
