import { defineStore } from 'pinia'
import Network from '@/Network'
import { ListCompanyUserResponse } from '@/Network/Types/Responses/CompanyResponses'
import { UserDataResponse } from '@/Network/Types/Responses/UserResponse'
import { LoginType } from '@/modules/public/Signin/schemas'
import { AuthResponse } from '@/Network/Types/Responses/AuthResponses'

export type AuthState = AuthResponse

export type UserState = {
  users: ListCompanyUserResponse | undefined
  listUsers: { title: string; subtitle: string; value: string }[]
  userData: UserDataResponse | undefined | null
  isLoading: boolean
  accessToken?: string | undefined | null
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    users: undefined,
    listUsers: [],
    userData: undefined,
    isLoading: false,
    accessToken: undefined
  }),
  actions: {
    async getAllUsers(): Promise<void> {
      this.isLoading = true
      try {
        const { count, data } = await Network.user.getAllUsers()
        this.users = { data, count }
        this.listUsers = data.map(({ user }) => ({
          title: user.name,
          subtitle: user.email,
          value: String(user.id)
        }))
      } catch (error) {
        console.error('Erro ao buscar todos os usuários:', error)
      } finally {
        this.isLoading = false
      }
    },

    async getUserData(): Promise<void> {
      this.isLoading = true
      try {
        this.userData = await Network.user.getUserData()
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error)
        this.userData = null
      } finally {
        this.isLoading = false
      }
    },
    async updateUserData(updatedData: Partial<UserDataResponse>): Promise<UserDataResponse> {
      this.isLoading = true
      try {
        const updatedUserData = await Network.user.updateUserData(updatedData)

        this.userData = updatedUserData

        return updatedUserData
      } catch (error) {
        console.error('Erro ao atualizar os dados do usuário:', error)
        throw error
      } finally {
        this.isLoading = false
      }
    },
    async changePassword(password: string, repeatPassword: string): Promise<void> {
      this.isLoading = true
      try {
        await Network.user.changeCompanyUserPassword({ password, repeatPassword })
      } catch (error) {
        console.error('Erro ao alterar a senha:', error)
        throw error
      } finally {
        this.isLoading = false
      }
    },
    async login(data: LoginType): Promise<void> {
      this.isLoading = true
      try {
        const authData: AuthState = await Network.auth.loginUserCompany(data)
        this.accessToken = authData.access_token
      } catch (error) {
        console.error('Erro ao realizar login:', error)
        throw error
      } finally {
        this.isLoading = false
      }
    }
  }
})
