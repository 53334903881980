<template>
  <modal-vue
    @close="handleToggleModal"
    :modalActive="modals[modalName]"
    :hasFooterBorder="true"
    isFilterModal
  >
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Filtrar unidades</strong>
      </div>
    </template>
    <v-row dense>
      <v-col cols="12">
        <v-combobox
          v-model="selectedNames"
          label="Insira os Nomes"
          multiple
          tags
          chips
          clearable
          variant="outlined"
          closable-chips
          color="primary20"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-combobox
          v-model="selectedCnpjs"
          label="Insira os CNPJs"
          multiple
          tags
          chips
          clearable
          variant="outlined"
          closable-chips
          color="primary20"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          ref="ufAutocomplete"
          v-model="selectedUfs"
          :items="StatesFromBR"
          label="Selecione as UFs"
          multiple
          chips
          clearable
          variant="outlined"
          closable-chips
          color="primary20"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-combobox
          v-model="selectedCompanies"
          label="Selecione as Empresas"
          multiple
          tags
          chips
          clearable
          variant="outlined"
          closable-chips
          color="primary20"
          hide-details
        />
      </v-col>
    </v-row>
    <template #footer>
      <v-row class="pt-4">
        <v-col cols="6">
          <ghost-button @click="clearFilters" width="100%" text="Limpar filtros" />
        </v-col>
        <v-col cols="6">
          <filled-button @click="applyFilters" text="Aplicar Filtros" height="52px" width="100%" />
        </v-col>
      </v-row>
    </template>
  </modal-vue>
</template>

<script setup lang="ts">
import { defineEmits, ref } from 'vue'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import StatesFromBR from '@/config/StatesFromBR'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { storeToRefs } from 'pinia'

/* ******** Modal Props and Actions Default *********** */
const modalName = EModals.FILTER_UNIT_MODAL
const { modals } = storeToRefs(useModalStore())
const modalStore = useModalStore()
const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
}
/* **************************************************** */

const emit = defineEmits(['apply-filters', 'clear-filters'])

const selectedNames = ref<string[]>([])
const selectedCnpjs = ref<string[]>([])
const selectedUfs = ref<string[]>([])
const selectedCompanies = ref<string[]>([])

const applyFilters = async () => {
  emit('apply-filters', {
    names: selectedNames.value,
    cnpjs: selectedCnpjs.value,
    ufs: selectedUfs.value,
    companies: selectedCompanies.value
  })
  handleToggleModal()
}

const clearFilters = () => {
  selectedNames.value = []
  selectedCnpjs.value = []
  selectedUfs.value = []
  selectedCompanies.value = []

  emit('clear-filters')
  handleToggleModal()
}
</script>
