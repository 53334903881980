import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import {
  ChangePasswordWithTokenRequest,
  ChangePasswordRequest,
  TokenValidateRequest
} from '../Types/Requests/UserRequest'
import {
  ChangePasswordWithTokenResponse,
  VerifyCpfResponse,
  VerifyEmailResponse,
  UserDataResponse
} from '../Types/Responses/UserResponse'
import { ListCompanyUserResponse } from '../Types/Responses/CompanyResponses'

export const STORAGE_TOKEN_KEY = ``

export default class UserService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/users'
  }

  async getAllUsers(search?: string): Promise<ListCompanyUserResponse> {
    const response = await request.get<ListCompanyUserResponse>(this.getCompletePath('/'), {
      search: search ? search : undefined
    })
    return {
      data: response.data.data,
      count: response.data.count
    }
  }

  async validateToken(payload: TokenValidateRequest): Promise<void> {
    await request.post<void>(this.getCompletePath('/validate-token'), payload)
  }

  async changePassword(
    payload: ChangePasswordWithTokenRequest
  ): Promise<ChangePasswordWithTokenResponse> {
    const { data } = await request.post<ChangePasswordWithTokenResponse>(
      this.getCompletePath('/change-password-with-token'),
      payload
    )
    return data
  }

  async changeCompanyUserPassword(
    payload: ChangePasswordRequest
  ): Promise<ChangePasswordWithTokenResponse> {
    const { data } = await request.post<ChangePasswordWithTokenResponse>(
      this.getCompletePath('/change-password-with-bearer'),
      payload
    )
    return data
  }

  async verifyEmail(email: string): Promise<VerifyEmailResponse> {
    const { data } = await request.get<VerifyEmailResponse>(this.getCompletePath('/verify-email'), {
      email
    })
    return data
  }
  async verifyCpf(cpf: string): Promise<VerifyCpfResponse> {
    const { data } = await request.get<VerifyCpfResponse>(this.getCompletePath('/verify-cpf'), {
      cpf
    })
    return data
  }
  async getUserData(): Promise<UserDataResponse> {
    const { data } = await request.get<UserDataResponse>(this.getCompletePath('/user-data'))
    return data
  }
  async updateUserData(updatedData: Partial<UserDataResponse>): Promise<UserDataResponse> {
    const { data } = await request.put<UserDataResponse>(
      this.getCompletePath('/update-user'),
      updatedData
    )
    return data
  }

  async toggleUserStatus(userId: number, isActive: boolean): Promise<void> {
    await request.put<void>(this.getCompletePath(`/toggle-status?userId=${userId}`), {
      isActive: isActive
    })
  }
}
