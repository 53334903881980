<template>
  <modal-vue
    @close="handleToggleModal"
    :modalActive="modals[modalName]"
    :hasFooterBorder="true"
    isFilterModal
  >
    <template #header>
      <p class="font-bold">Editar Grupo</p>
    </template>
    <base-form
      v-model:name="name"
      v-model:email="email"
      v-model:company="company"
      v-model:users="users"
      :isUpdateGroup="true"
    />
    <template #footer>
      <v-row class="pt-4">
        <v-col cols="12" md="6">
          <ghost-button @click="handleToggleModal" width="100%" text="Cancelar" />
        </v-col>
        <v-col cols="12" md="6">
          <filled-button
            :loading="isLoading"
            @click="handleUpdate"
            text="Editar Grupo"
            height="52px"
            width="100%"
          />
        </v-col>
      </v-row>
    </template>
  </modal-vue>
</template>

<script setup lang="ts">
import { PropType, ref, onBeforeUnmount, onUpdated } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { useGroupStore } from '@/pinia-store/useGroupStore'
import { useToast } from 'vue-toastification'
import { CreateGroupRequest } from '@/Network/Types/Requests/GroupRequests'
import { GroupResponse } from '@/Network/Types/Responses/GroupResponses'
import BaseForm from './components/base.form.vue'
import { DEFAULT_MESSAGES } from '@/hooks/useCommon'
/* ******** Modal Props and Actions Default *********** */
const modalName = EModals.UPDATE_GROUP_MODAL
const { modals } = storeToRefs(useModalStore())
const modalStore = useModalStore()
const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
}
/* **************************************************** */
const toast = useToast()
const groupStore = useGroupStore()

const props = defineProps({
  group: {
    type: Object as PropType<GroupResponse>,
    default: null
  }
})

const isLoading = ref(false)
const company = ref<string | null>(null)
const name = ref<string>('xpto')
const email = ref<string>('')
const users = ref<string[]>([])

onUpdated(() => {
  console.log('updated', props)
  company.value = String(props.group?.company.id)
  name.value = props.group?.name ?? ''
  email.value = props.group?.email ?? ''
  users.value = props.group?.userGroups.map(({ user }) => String(user.id)) ?? []
})

const handleUpdate = async () => {
  try {
    isLoading.value = true
    const payload: CreateGroupRequest = {
      name: name.value,
      email: email.value,
      companyId: Number(company.value),
      users: users.value.map((u) => Number(u))
    }
    await groupStore.update({ groupId: props.group.id, payload })
    toast.success('Grupo editado com sucesso')
    handleToggleModal()
    clearAllInputs()
  } catch (error) {
    toast.error(DEFAULT_MESSAGES.error)
  } finally {
    isLoading.value = false
    await groupStore.getAllGroups()
  }
}

const clearAllInputs = () => {
  name.value = ''
  email.value = ''
  company.value = ''
}
onBeforeUnmount(() => {
  clearAllInputs()
})
</script>
