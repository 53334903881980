<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]">
    <template #header>
      <p class="font-bold">Excluir escala</p>
    </template>
    <p class="text-base font-normal">
      Tem certeza que deseja excluir a escala?<br />Essa ação não poderá ser desfeita!
    </p>
    <template #footer>
      <div class="flex justify-center gap-4">
        <outline-button width="119px" @click="handleToggleModal" text="Cancelar" />
        <filled-button
          :disabled="isLoading"
          :loading="isLoading"
          text="Excluir"
          width="119px"
          @click="handleRemove"
        />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
export default {
  components: {
    ModalVue,
    OutlineButton,
    FilledButton
  },
  props: {
    scheduleId: { required: true, type: Number }
  },
  setup(props) {
    const modalName = EModals.REMOVE_SCHEDULE
    const scheduleStore = useScheduleStore()
    const modalStore = useModalStore()
    const { modals } = storeToRefs(useModalStore())

    const successRemove = ref(false)
    const isLoading = ref(false)

    const handleToggleModal = () => {
      modalStore.toggleModal(modalName)
    }

    const handleRemove = async () => {
      if (!props.scheduleId) return
      try {
        isLoading.value = true
        await scheduleStore.remove(props.scheduleId)
        modalStore.alertDescription = 'Escala excluida com sucesso!'
        modalStore.toggleModal(modalName)
        modalStore.toggleShowAlert()
      } finally {
        isLoading.value = false
      }
    }

    return { modalName, modals, successRemove, isLoading, handleToggleModal, handleRemove }
  }
}
</script>
